import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Select from 'react-select';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {
  FaPhone,
  FaTimes,
  FaFileUpload,
  FaCalendarAlt,
  FaCaretRight,
  FaCaretDown,
} from 'react-icons/fa';

import { BoldText, Text, RegularText } from '../components/common/Text';
import HraContainer from '../components/hra/App';
import CloseConsultationModal from '../components/consultations/CloseConsultationModal';
import UpdateInfoModal from '../components/UpdateInfoModal';
import UpdateInfoModalLic from '../components/UpdateInfoModalLic';
import StyledTappable from '../components/common/StyledTappable';
import {
  closeConsult,
  fetchConsults,
  callMade,
  resetActiveConsult,
  updateConsults,
  videoCallMade,
  setUserInfo,
  setProposerDetails,
} from '../actions';
import { PrimaryButton } from '../components/common/Buttons';
import Modal from './common/Modal';
import DatePicker from 'react-datepicker';

import {
  markFormApproved,
  markFormComplete,
  previewForm,
  fetchCallLogs,
  saveRemarksForCall,
  loginInfo,
  updatePhone,
  reopenCase,
  rejectCase,
  refetchCallRecording,
  sendSmsBeforeAttempt,
  convertToArmsoft,
  convertToTele,
  convertOldForm,
  sendTranscript,
  updateDisclosure,
  getFormQuestions,
  updateTranscript,
  videoScreenshotByConsultationId,
  addVideoByConsultationId,
  markTuIncomplete,
  markFollowupUser,
  specialNumber,
  resendCallback,
  getVideoDetail,
  allowNightCalling,
  transferToVendor,
  markDuplicate,
  sendUserEmailLink,
  uploadScreenshotByUserId,
  getFeedbackBy,
  markRuleEngineFormComplete,
  addAudioByConsultationId,
  addAudioByUserId,
  toggleDiscrepancy,
  getDiscrepencyService,
  toggleDiscrepancyService,
  splitCase,
  caseCompleted,
  getFormValService,
  toggleFormService,
  jointMerAegonToggle,
  updateLanguage,
  addTranscriptByUserId,
  updateAssisted,
  markRecordingViewInitiated,
  markOnHold,
  setPlaytimeRemarks,
} from '../services';
import UploadingComponent from '../components/common/UploadingComponent';
import SelectVideoModal from '../components/consultations/SelectVideoModal';
import { submitFeedbackAPI } from '../services';
import SendInDailyReportModal from '../components/consultations/SendInDailyReportModal';
import { showError, showSuccess } from '../utils';

import { Button as AntdButton, Modal as AntdModal, message } from 'antd';
import DraggablePlayer from '../components/common/DraggablePlayer';
import PlaytimeRemarksModal from '../components/consultations/PlaytimeRemarksModal';

const OuterContainer = styled.div`
  display: flex;
  position: relative;
  flex: 2 0;
  flex-direction: column;
  align-items: stretch;
  border: 1px solid #d8d8d8;
  overflow: hidden;
`;

const CallIcon = styled(FaPhone)`
  margin-right: 8px;
`;

const NoteText = styled(Text)`
  font-size: 1.8rem;
  margin: 10px 14px;
  color: #56c02c;
`;

const TabContainer = styled.div`
  display: flex;
  background: #f5f5f5;
  align-items: stretch;
  justify-content: space-between;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.23);
`;

const TabItemContainer = styled(StyledTappable)`
  padding: 1rem 4rem;
  border-bottom: ${(props) => (props.active ? '2px solid #8852CC' : 'none')};
`;

const TabActiveItemText = styled(BoldText)`
  font-size: 1.6rem;
  color: #8852cc;
`;

const TabItemText = styled(Text)`
  font-size: 1.6rem;
  opacity: 0.4;
  /* z-index: -1; */
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
`;

const FormSteps = styled.div`
  padding: 10px;
`;

const PreviewButton = styled(PrimaryButton)`
  float: left;
`;

const CallLogCard = styled.div`
  border-color: ${(props) =>
    props.status === 'unanswered'
      ? '#dc3545'
      : props.status === 'answered'
      ? '#28a745'
      : '#ffc107'};
`;

const RecordingButton = styled(StyledTappable)`
  justify-content: flex-start;
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  width: 80%;
  padding: 1.8rem 2rem 3rem 2rem;
  border-radius: 0.8rem;
  margin: 0 1.6rem;
  text-align: center;
  max-height: 600px;
  height: 100%;
  @media (min-height: 900px) {
    max-height: 800px;
  }
`;
const CloseContainer = styled(StyledTappable)`
  align-self: flex-end;
`;

const CloseIcon = styled(FaTimes)`
  font-size: 18px;
`;
const ActionContainer = styled(StyledTappable)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const UploadButton = styled(FaFileUpload)`
  margin-right: 8px;
`;
const Cell = styled(RegularText)`
  color: 'rgba(0,0,0,0.7)';
  margin-right: 8px;
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1.625rem;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;

const ButtonText = styled(BoldText)`
  color: white;
  font-size: 1.5rem;
  padding: 0.625rem 1.625rem;
  /* text-transform: uppercase; */
  text-align: center;
`;

const FollowupModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 35%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

const LabelText = styled(Text)`
  margin-top: 2rem;
  font-size: 1.6rem;
  text-align: center;
  align-self: center;
  margin-bottom: 2rem;
  color: #000;
  display: flex;
  flex-direction: row;
`;

const CalendarIcon = styled(FaCalendarAlt)`
  font-size: 1.5rem;
  color: #fff;
  margin-right: 1rem;
`;

const FeedbackSelectOptions = [
  { value: 'Good', label: 'Good' },
  { value: 'Average', label: 'Average' },
  { value: 'Poor', label: 'Poor' },
  { value: 'Critical Error', label: 'Critical Error' },
  { value: 'Excellent', label: 'Excellent' },
];

const NPSRatingOptions = [
  { value: '1', label: 'Yes' },
  { value: '0', label: 'No' },
];

class ProfileContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      discrepancyval: true,
      enableForm: true,
      jointMER: false,
      showCloseConsultationModal: false,
      savingForm: false,
      formPreviewed: false,
      savingRemarks: false,
      savingRemarksFor: '',
      phoneUpdated: false,
      submissionRemarks: '',
      activeTab: 'profile',
      callLogs: [],
      callRemarks: {},
      callRemarksOptions: [],
      showPreviewModal: false,
      previewReport: '',
      reportViewed: false,
      smsSent: false,
      activeCallLogTab: 'voice',
      convertToArmsoft: false,
      updatedNumber: '',
      convertToTele: false,
      convertOldForm: false,
      updateInfoModal: false,
      selectedRelative: null,
      updatedDisclosure: null,
      updatingDisclosure: false,
      uploadSuccess: false,
      uploadSuccess2: false,
      uploadVideoSuccess: false,
      tuIncompleteMarked: false,
      tuIncompleteRemark: '',
      updateInfoModalLic: false,
      showFollowupModal: false,
      followupDate: new Date(),
      followupRemark: null,
      reopenRemark: '',
      feedback: '',
      sessionId: '',
      showSelectVideoModal: false,
      reportUrl: '',
      additionalReportUrl: [],
      linkToSend: '',
      userEmail: '',
      linkSent: false,
      duplicateMarked: false,
      duplicateRemark: '',
      alertFeedback: false,
      expandedPdf: null,
      uploadAudioSuccess: false,
      uploadingAudio: false,
      uploadingTranscript: false,
      uploadTrascriptSuccess: false,
      optVal: '',
      caseCompletedCheck: false,
      language: '',
      languageUpdated: false,
      languageSelect: false,
      newRemarkVal: '',
      languageOptions: [
        { value: 'Telugu', label: 'Telugu' },
        { value: 'Hindi', label: 'Hindi' },
        { value: 'English', label: 'English' },
        { value: 'Tamil', label: 'Tamil' },
        { value: 'Malayalam', label: 'Malayalam' },
        { value: 'Kannada', label: 'Kannada' },
        { value: 'Bengali', label: 'Bengali' },
        { value: 'Marathi', label: 'Marathi' },
        { value: 'Gujarati', label: 'Gujarati' },
        { value: 'Assamese', label: 'Assamese' },
        { value: 'Kashmiri', label: 'Kashmiri' },
        { value: 'Konkani', label: 'Konkani' },
        { value: 'Manipuri', label: 'Manipuri' },
        { value: 'Nepali', label: 'Nepali' },
        { value: 'Oriya', label: 'Oriya' },
        { value: 'Punjabi', label: 'Punjabi' },
        { value: 'Urdu', label: 'Urdu' },
      ],
      npsVal: '',
      recordingStarted: false,
      selectedRecordingUrl: null,
      showPlayTimeRemarksModal: false,
      previousSelectedCallInfo: null,
    };
  }

  componentDidMount() {
    const input = document.querySelector('#updated-phone-input');
    if (input) {
      input.value = '';
    }
    const input2 = document.querySelector('#tu-incomplete-input');
    if (input2) {
      input2.value = '';
    }

    this.getCallRemarksOptions().then(() => {
      this.fetchCallLogs();
    });
    if (this.props.only) {
      this.setState({
        activeTab: this.props.only,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { callMade, consultationId, videoCallMade, insurer } = this.props;
    const previousConsultationId = prevProps.consultationId;
    const previousCallMade = prevProps.callMade;
    const previousVideoCallMade = prevProps.videoCallMade;
    if (consultationId !== previousConsultationId) {
      console.log('Updated');
      const input = document.querySelector('#updated-phone-input');
      if (input) {
        input.value = '';
      }
      const input2 = document.querySelector('#tu-incomplete-input');
      if (input2) {
        input2.value = '';
      }
      this.fetchCallLogs();
      if (insurer === 'ipru') {
        this.getDiscrepency();
        this.getFormVal();
      }

      this.setState({
        savingForm: false,
        formPreviewed: false,
        phoneUpdated: false,
        smsSent: false,
        activeCallLogTab: 'voice',
        convertToArmsoft: false,
        convertToTele: false,
        convertOldForm: false,
        updatedNumber: '',
        updatedDisclosure: null,
        updatingDisclosure: false,
        uploadSuccess: false,
        uploadSuccess2: false,
        uploadVideoSuccess: false,
        tuIncompleteRemark: '',
        reopenRemark: '',
        feedback: '',
        tuIncompleteMarked: false,
        showFollowupModal: false,
        followupDate: new Date(),
        followupRemark: null,
        linkToSend: '',
        userEmail: '',
        linkSent: false,
        duplicateMarked: false,
        duplicateRemark: '',
        alertFeedback: false,
        expandedPdf: null,
        feedback: '',
        optVal: '',
        npsVal: '',
        caseCompletedCheck: false,
        languageUpdated: false,
        recordingStarted: false,
        selectedRecordingUrl: null,
        showPlayTimeRemarksModal: false,
        previousSelectedCallInfo: null,
      });
      return;
    }
    if (prevProps.only !== this.props.only) {
      this.setState({
        activeTab: this.props.only,
      });
    }
    console.log(videoCallMade, previousVideoCallMade);
    if (callMade && callMade !== previousCallMade) {
      this.fetchCallLogs();
    }
    if (videoCallMade) {
      console.log('fetchCallLogs()');
      this.fetchCallLogs();
    }
    if (prevState.activeCallLogTab !== this.state.activeCallLogTab) {
      this.fetchCallLogs();
    }
  }

  getFormQuestions() {
    const { activeConsult, dispatch } = this.props;
    const userId = activeConsult.user.userId;
    if (!userId) {
      return this.setState({
        loading: false,
      });
    }
    this.setState({
      loading: true,
    });

    getFormQuestions(userId)
      .then((res) => {
        console.log('bye', res.proposerInfo);
        const proposerDetails = res.proposerInfo;
        dispatch(setProposerDetails(proposerDetails));
      })
      .catch((err) => {
        this.setState({
          errorMessage:
            (err.data || {}).errorMessage ||
            'Something went wrong. Please try again later',
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  updateDisclosure = () => {
    const { activeConsult, dispatch } = this.props;
    const userId = activeConsult.user.userId;
    const disclosure = this.state.updatedDisclosure;
    this.setState({
      updatingDisclosure: true,
    });
    updateDisclosure(userId, disclosure)
      .then(() => {
        this.setState({
          updatedDisclosure: null,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.getFormQuestions();
        this.setState({
          updatingDisclosure: false,
          updatedDisclosure: null,
        });
      });
  };

  getCallRemarksOptions() {
    const { insurer, dispatch } = this.props;
    return loginInfo()
      .then((res) => {
        const { callRemarksOptions } = res;
        if (insurer === 'ipru') {
          // Setting armsoft property on refresh
          dispatch(
            setUserInfo({
              armsoft: res.doctor.armsoft === 1,
              changeInsurer: res.doctor.changeInsurer === 1,
              panNo: res.doctor.panNo,
              language: res.doctor.languages,
              email: res.doctor.email,
              vendorId: res.doctor.vendorId,
              updateTarget: res.doctor.updateTarget,
              isDoctorAvailableForCall: res.doctor.availableForCall === 1,
              instantTele: res.doctor.instantTele === 1,
            }),
          );
          // Transform the remarks into category wise arrays
          const categorizedRemarks = callRemarksOptions.reduce((acc, ele) => {
            console.log(acc);
            const findCategoryIndex = acc.findIndex(
              (innerEle) => ele.categoryId === innerEle.categoryId,
            );
            if (findCategoryIndex === -1) {
              acc.push({
                label: ele.categoryLabel,
                categoryId: ele.categoryId,
                options: [
                  {
                    value: ele.remarkId,
                    categoryId: ele.categoryId,
                    label: ele.label,
                    textBoxRemarks: ele.additionalText === 1,
                  },
                ],
              });
            } else {
              acc[findCategoryIndex].options.push({
                value: ele.remarkId,
                categoryId: ele.categoryId,
                label: ele.label,
                textBoxRemarks: ele.additionalText === 1,
              });
            }
            return acc;
          }, []);
          console.log('Categorized Remarks', categorizedRemarks);
          this.setState({
            callRemarksOptions: categorizedRemarks,
          });
        } else {
          dispatch(
            setUserInfo({
              changeInsurer: res.doctor.changeInsurer === 1,
              panNo: res.doctor.panNo,
              language: res.doctor.languages,
              email: res.doctor.email,
              vendorId: res.doctor.vendorId,
              updateTarget: res.doctor.updateTarget,
              isDoctorAvailableForCall: res.doctor.availableForCall === 1,
              instantTele: res.doctor.instantTele === 1,
            }),
          );
          this.setState({
            callRemarksOptions: callRemarksOptions.map((ele) => ({
              value: ele.id,
              label: ele.label,
              textBoxRemarks: ele.optional === 0,
            })),
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  fetchCallLogs() {
    const { dispatch, doctorType, activeConsult } = this.props;
    const { callRemarks } = this.state;
    dispatch(videoCallMade(false));
    if (doctorType === 'mbbs') {
      fetchCallLogs(activeConsult.user.userId, this.state.activeCallLogTab)
        .then((res) => {
          const { calls } = res;
          calls.forEach((ele) => {
            if (ele.remarkId) {
              callRemarks[ele.ucid || ele.callId] = {
                value: ele.remarkId,
                label: this.findRemarkById(ele.remarkId),
                textBoxRemarks: ele.remarks,
                categoryId: ele.categoryId,
              };
            } else {
              callRemarks[ele.ucid || ele.callId] = {};
            }
          });
          this.setState({
            callLogs: calls,
            callRemarks: Object.assign({}, callRemarks),
            followupRemark: null,
          });
          dispatch(callMade(false));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  findRemarkById(remarkId) {
    const { insurer } = this.props;
    const { callRemarksOptions } = this.state;
    let callRemarks = callRemarksOptions;
    if (insurer === 'ipru') {
      callRemarks = callRemarksOptions.reduce((acc, ele) => {
        return acc.concat(ele.options);
      }, []);
    }
    const remark = callRemarks.find((ele) => ele.value === remarkId);
    if (remark) {
      return remark.label;
    } else {
      return null;
    }
  }

  hideModal = () => {
    this.setState({
      showCloseConsultationModal: false,
      updateInfoModal: false,
      selectedRelative: false,
      updateInfoModalLic: false,
      showFollowupModal: false,
      followupRemark: null,
      showSelectVideoModal: false,
      linkToSend: '',
      userEmail: '',
      linkSent: false,
      expandedPdf: null,
      feedback: '',
      optVal: '',
      npsVal: '',
    });
  };

  transferToVendor = () => {
    const { activeConsult } = this.props;
    transferToVendor([activeConsult.user.userId], 1)
      .then(() => {
        this.props.dispatch(resetActiveConsult());
        this.fetchConsults();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          savingForm: false,
          showPreviewModal: false,
          reportViewed: false,
        });
      });
  };

  markDuplicate = () => {
    const { duplicateRemark } = this.state;
    const { activeConsult } = this.props;
    const body = {
      applicationId: duplicateRemark,
      userId: activeConsult.user.userId,
      consultationId: activeConsult.consultationId,
      name: activeConsult.user.userName,
    };
    markDuplicate(body)
      .then(() => {
        this.setState(
          {
            duplicateMarked: true,
            duplicateRemark: '',
          },
          () => {
            this.fetchCallLogs();
          },
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  closeConsult = (reason) => {
    console.log({
      reason,
    });
    const { userId, consultationId, authToken, dispatch } = this.props;
    dispatch(closeConsult(userId, consultationId, reason, authToken));
  };

  previewForm = () => {
    const { activeConsult } = this.props;
    this.setState({
      savingForm: true,
      feedback: '',
      optVal: '',
      npsVal: '',
    });
    previewForm(activeConsult.user.userId)
      .then((res) => {
        const result = res.result;
        if (Array.isArray(result)) {
          result.forEach((ele) => {
            const anchor = document.createElement('a');
            anchor.setAttribute('href', ele.fileUrl);
            anchor.setAttribute('target', '_blank');
            anchor.click();
          });
          this.setState({
            formPreviewed: true,
          });
          return;
        }
        if (result) {
          // Open the form in a new tab
          // const anchor = document.createElement('a');
          // anchor.setAttribute('href', result);
          // anchor.setAttribute('target', '_blank');
          // anchor.click();
          this.setState({
            showPreviewModal: true,
            formPreviewed: true,
            previewReport: result.url || result,
            reportUrl: result.url || result,
            additionalReportUrl:
              result &&
              result.additionalTranscripts &&
              result.additionalTranscripts.length > 0
                ? result.requirementCode === 'PPMC_DIGI'
                  ? [...result.additionalTranscripts]
                  : [
                      ...result.additionalTranscripts,
                      {
                        url: result.url,
                        header: 'VMER',
                        key: 'VMER',
                      },
                    ]
                : [],
            expandedPdf: result.url,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          savingForm: false,
        });
      });
  };

  fetchConsults() {
    const { dispatch, activeFilterName, authToken } = this.props;
    dispatch(
      fetchConsults({
        authToken,
        filters: [
          {
            name: activeFilterName,
            isSelected: true,
          },
        ],
      }),
    );
  }

  sendUserEmailLink = () => {
    const { activeConsult } = this.props;
    const { linkToSend, userEmail } = this.state;
    const userId = activeConsult.user.userId;
    const body = {
      link: linkToSend,
      email: userEmail,
      userId,
    };
    sendUserEmailLink(body)
      .then(() => {
        this.setState({
          linkSent: true,
          linkToSend: '',
          userEmail: '',
        });
        setTimeout(() => {
          this.setState({
            linkSent: false,
          });
        }, 10000);
      })
      .catch(() => {});
  };

  markFormComplete = () => {
    const { activeConsult, insurer } = this.props;
    const { submissionRemarks } = this.state;
    if (!this.state.languageUpdated) {
      return showError({
        data: {
          errorMessage: 'Please update call language',
        },
      });
    }
    this.setState({
      savingForm: true,
    });

    if (activeConsult.showForm === false && insurer === 'max') {
      return markRuleEngineFormComplete(activeConsult.user.userId, false)
        .then(() => {
          this.props.dispatch(resetActiveConsult());
          this.fetchConsults();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.setState({
            savingForm: false,
            showPreviewModal: false,
            reportViewed: false,
            expandedPdf: null,
            feedback: '',
            optVal: '',
            npsVal: '',
          });
        });
    }
    return markFormComplete(activeConsult.user.userId, submissionRemarks)
      .then(() => {
        if (insurer === 'ppmc') {
          this.props.dispatch(updateConsults(activeConsult.consultationId));
          return null;
        }
        this.props.dispatch(resetActiveConsult());
        this.fetchConsults();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          savingForm: false,
          showPreviewModal: false,
          reportViewed: false,
          expandedPdf: null,
          feedback: '',
          optVal: '',
          npsVal: '',
        });
      });
  };

  setRemarksForCall =
    (ucid, isCategory = false) =>
    (val, action) => {
      console.log(ucid);
      console.log(['setRemarksForCall']);
      const { callRemarks } = this.state;
      console.log(action, val);

      if (action.action === 'select-option' || action.action === 'set-value') {
        if (val.label === 'Language Preference') {
          this.setState({ languageSelect: true });
        }
      }
      if (action.action === 'select-option' || action.action === 'set-value') {
        if (
          val.label === 'User requested callback' ||
          (val.label === 'Call Later' && isCategory === false)
        ) {
          this.setState({
            showFollowupModal: ucid,
            followupRemark: {
              label: isCategory ? null : val.label,
              value: isCategory ? null : val.value,
              categoryId: val.categoryId,
              remarksRequired: val.textBoxRemarks,
              textBoxRemarks: val.textBoxRemarks ? '' : null,
            },
          });
          return;
        }
        callRemarks[ucid] = {
          label: isCategory ? null : val.label,
          value: isCategory ? null : val.value,
          categoryId: val.categoryId,
          remarksRequired: val.textBoxRemarks,
          textBoxRemarks: val.textBoxRemarks ? '' : null,
        };
        this.setState({
          callRemarks: Object.assign({}, callRemarks),
        });
      }
    };

  handleChange = (selectedOption) => {
    this.setState({ optVal: selectedOption.value });
  };

  saveRemarksForCall = (ucid, callType, userId) => () => {
    const { callRemarks, activeCallLogTab, languageSelect, language } =
      this.state;

    if (languageSelect && language != '') {
      this.updateLanguage();
    }

    this.setState({
      savingRemarks: true,
      savingRemarksFor: ucid,
    });
    console.log(callRemarks);
    saveRemarksForCall(
      ucid,
      callRemarks[ucid].value,
      callRemarks[ucid].textBoxRemarks,
      activeCallLogTab,
      userId,
    )
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          savingRemarks: false,
          savingRemarksFor: '',
        });
        this.fetchCallLogs();
      });
  };

  setCallTextBoxRemarks = (ucid) => (e) => {
    const { callRemarks } = this.state;
    callRemarks[ucid] = Object.assign(callRemarks[ucid], {
      textBoxRemarks: e.target.value,
    });
    this.setState({
      callRemarks: Object.assign({}, callRemarks),
    });
    this.setState({ language: e.target.value });
  };

  viewRecordingFile = (file) => () => {
    const anchor = document.createElement('a');
    anchor.setAttribute('href', file);
    anchor.setAttribute('target', '_blank');
    anchor.click();
  };

  markFormApproved = (submitBrute = false) => {
    const { activeConsult, insurer } = this.props;
    const { submissionRemarks } = this.state;
    const feedback = this.state.feedback;
    const dropOpt = this.state.optVal;
    let npsDropOpt;
    let isPlayTimeError = false;
    if (insurer === 'max') {
      npsDropOpt = this.state.npsVal;
    }
    if (!feedback || !dropOpt || (npsDropOpt !== undefined && !npsDropOpt)) {
      return showError({
        data: {
          errorMessage: 'Please provide Final Feedback',
        },
      });
    }
    this.submitFeedback();

    this.setState({
      savingForm: true,
    });
    if (activeConsult.showForm === false && insurer === 'max') {
      return markRuleEngineFormComplete(activeConsult.user.userId, true)
        .then(() => {
          this.setState({
            feedback: '',
            optVal: '',
            npsVal: '',
          });
          this.props.dispatch(resetActiveConsult());
          this.fetchConsults();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.setState({
            savingForm: false,
            showPreviewModal: false,
            reportViewed: false,
            expandedPdf: null,
            recordingStarted: false,
          });
        });
    }
    return markFormApproved(
      activeConsult.user.userId,
      submissionRemarks,
      submitBrute,
    )
      .then((res) => {
        if (res.playTimeError) {
          isPlayTimeError = true;
          this.setState({ showPlayTimeRemarksModal: true });
        } else {
          this.props.dispatch(resetActiveConsult());
          this.fetchConsults();
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally((res) => {
        if (!isPlayTimeError) {
          this.setState({
            savingForm: false,
            showPreviewModal: false,
            reportViewed: false,
            expandedPdf: null,
            feedback: '',
            optVal: '',
            npsVal: '',
            recordingStarted: false,
          });
        }
      });
  };

  submitPlaytimeRemarks = (remarks) => {
    setPlaytimeRemarks({
      userId: this.props.activeConsult.user.userId,
      remarks,
    })
      .then((res) => {
        if (res.message === 'success') {
          this.markFormApproved(true);
          this.setState({ showPlayTimeRemarksModal: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  updatePhone = (isAlternate = false) => {
    const { activeConsult } = this.props;
    const input = document.querySelector('#updated-phone-input').value;
    if (input.length < 6) {
      return null;
    }
    const userId = activeConsult.user.userId;
    updatePhone(userId, input, isAlternate)
      .then(() => {
        this.setState(
          {
            phoneUpdated: true,
            smsSent: false,
            updatedNumber: '',
          },
          () => {
            setTimeout(() => {
              this.setState({
                phoneUpdated: false,
              });
              const input = document.querySelector('#updated-phone-input');
              if (input) {
                input.value = '';
              }
            }, 600);
          },
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  markTuIncomplete = () => {
    const { activeConsult, qc } = this.props;
    const input = document.querySelector('#tu-incomplete-input').value;
    if (input.length < 6) {
      return null;
    }
    const userId = activeConsult.user.userId;
    const body = {
      remark: input,
      userId,
    };
    let promise = markTuIncomplete;
    if (qc) {
      promise = markOnHold;
    }
    promise(body)
      .then(() => {
        this.setState(
          {
            tuIncompleteMarked: true,
            tuIncompleteRemark: '',
          },
          () => {
            setTimeout(() => {
              const input = document.querySelector('#tu-incomplete-input');
              if (input) {
                input.value = '';
              }
              this.props.dispatch(resetActiveConsult());
              this.fetchConsults();
            }, 600);
          },
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  specialNum = () => {
    const { activeConsult } = this.props;
    const userId = activeConsult.user.userId;
    specialNumber(userId)
      .then((res) => {
        this.getFormQuestions();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  convertTele = () => {};

  allowNightCalling = () => {
    const { activeConsult } = this.props;
    const userId = activeConsult.user.userId;
    allowNightCalling(userId)
      .then((res) => {
        this.getFormQuestions();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  reopenCase = () => {
    const { activeConsult } = this.props;
    const userId = activeConsult.user.userId;
    const remark = this.state.reopenRemark;
    reopenCase(userId, false, false, remark)
      .then((res) => {
        this.setState({ smsSent: false });
        if (activeConsult.labels[1].text === 'Completed') {
          this.props.dispatch(resetActiveConsult());
        } else {
          this.props.dispatch(updateConsults(activeConsult.consultationId));
        }
        this.fetchConsults();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateTranscript = () => {
    const { activeConsult } = this.props;
    this.setState({
      savingForm: true,
    });
    const userId = activeConsult.user.userId;
    updateTranscript(userId)
      .then((res) => {
        this.setState({
          smsSent: false,
        });
        this.props.dispatch(updateConsults(activeConsult.consultationId));
        this.fetchConsults();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          savingForm: false,
        });
      });
  };

  submitFeedback = async () => {
    const { activeConsult } = this.props;

    const userId = activeConsult.user.userId;
    const insurer = this.props.insurer;
    const feedback = this.state.feedback;
    const dropOpt = this.state.optVal;

    let npsDropOpt;
    if (insurer === 'max') {
      npsDropOpt = this.state.npsVal;
    }

    //getOptionVal();

    const doctorId = await getFeedbackBy(userId, insurer);

    await submitFeedbackAPI(
      userId,
      insurer,
      doctorId,
      feedback,
      dropOpt,
      npsDropOpt,
    )
      .then((res) => {
        if (res.message === 'success') {
          this.setState({ alertFeedback: true });
        }
      })
      .catch((err) => {
        console.log(err);
        //alert error
        // this.setState({ alertFeedback: false })
      });
  };

  reopenCaseForQC = () => {
    const { activeConsult } = this.props;
    const userId = activeConsult.user.userId;
    reopenCase(userId, true)
      .then((res) => {
        this.setState({
          smsSent: false,
        });
        if (activeConsult.labels[1].text === 'Completed') {
          this.props.dispatch(resetActiveConsult());
        } else {
          this.props.dispatch(updateConsults(activeConsult.consultationId));
        }
        this.fetchConsults();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  reopenForm = () => {
    const { activeConsult } = this.props;
    const userId = activeConsult.user.userId;
    reopenCase(userId, false, false, '', true)
      .then((res) => {
        this.setState({
          smsSent: false,
        });
        if (activeConsult.labels[1].text === 'Completed') {
          this.props.dispatch(resetActiveConsult());
        } else {
          this.props.dispatch(updateConsults(activeConsult.consultationId));
        }
        this.fetchConsults();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  repeatCase = () => {
    const { activeConsult } = this.props;
    const userId = activeConsult.user.userId;
    reopenCase(userId, false, true)
      .then((res) => {
        this.setState({
          smsSent: false,
        });
        if (activeConsult.labels[1].text === 'Completed') {
          this.props.dispatch(resetActiveConsult());
        } else {
          this.props.dispatch(updateConsults(activeConsult.consultationId));
        }
        this.fetchConsults();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  resendCallback = () => {
    const { activeConsult } = this.props;
    this.setState({
      savingForm: true,
    });
    const consultationId = activeConsult.consultationId;
    const userId = activeConsult.user.userId;
    resendCallback(consultationId, userId)
      .then((res) => {
        this.setState({
          smsSent: false,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          savingForm: false,
        });
      });
  };

  sendTranscript = () => {
    const { activeConsult } = this.props;
    const userId = activeConsult.user.userId;
    sendTranscript(userId)
      .then((res) => {
        this.setState({
          smsSent: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  caseCompleted = () => {
    this.setState({
      loading: true,
    });
    const { activeConsult } = this.props;
    const userId = activeConsult.user.userId;
    caseCompleted(userId)
      .then((res) => {
        console.log('Case completed');
        this.setState({
          caseCompletedCheck: true,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  rejectCase = (dnd = false) => {
    const { activeConsult } = this.props;
    const userId = activeConsult.user.userId;
    const body = {
      userId,
      dnd,
    };
    rejectCase(body)
      .then((res) => {
        this.props.dispatch(resetActiveConsult());
        this.fetchConsults();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  smsBeforeAttempt = () => {
    this.setState({
      loading: true,
    });
    const { activeConsult } = this.props;
    const userId = activeConsult.user.userId;
    sendSmsBeforeAttempt(userId)
      .then((res) => {
        console.log('SMS sent');
        this.setState({
          smsSent: true,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };
  onIframeLoad = () => {
    const iframe =
      document.getElementById('pdfview').contentWindow.window.document;
    if (iframe) {
      const viewerContainer = iframe.querySelector('#viewerContainer');
      console.log(viewerContainer);
      viewerContainer.addEventListener('scroll', (e) => {
        var h = viewerContainer,
          b = iframe,
          st = 'scrollTop',
          sh = 'scrollHeight';
        const scrollPercent =
          ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
        if (scrollPercent >= 80 && !this.state.reportViewed) {
          this.setState({
            reportViewed: true,
          });
        }
      });
    } else {
      console.log('no');
    }
  };

  refetchCallRecording = (ucid) => () => {
    refetchCallRecording(ucid)
      .then(() => {
        this.fetchCallLogs();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  convertToArmsoft = (userId) => {
    convertToArmsoft(userId)
      .then((res) => {
        this.setState({
          convertToArmsoft: true,
        });
        this.props.dispatch(resetActiveConsult());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  convertOldForm = (userId) => {
    convertOldForm(userId)
      .then((res) => {
        this.setState({
          convertOldForm: true,
        });
        this.props.dispatch(resetActiveConsult());
        this.fetchConsults();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  convertToTele = (userId, type) => {
    convertToTele(userId, type)
      .then((res) => {
        this.setState({
          convertToTele: true,
        });
        this.props.dispatch(resetActiveConsult());
        this.fetchConsults();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateRelative = (relative) => {
    console.log(true);
    this.setState({
      selectedRelative: relative,
      updateInfoModal: true,
    });
    return;
  };

  updateRelativeLic = (relative) => {
    console.log(true);
    this.setState({
      selectedRelative: relative,
      updateInfoModalLic: true,
    });
    return;
  };

  uploadScreenshot(type) {
    document
      .querySelector(
        `#screenshotFile-${type}-${this.props.activeConsult.consultationId}`,
      )
      .click();
  }

  uploadAudio() {
    document
      .querySelector(`#audioFile-${this.props.activeConsult.user.userId}`)
      .click();
  }

  uploadVideo() {
    document
      .querySelector(`#videoFile-${this.props.activeConsult.consultationId}`)
      .click();
  }

  uploadTranscript() {
    document
      .querySelector(`#transcriptFile-${this.props.activeConsult.user.userId}`)
      .click();
  }

  handleScreenshotUpload = (ev, type) => {
    const files = ev.target.files;
    console.log(files);
    if (files[0]) {
      const file = files[0];
      const inputTag = document.querySelector(
        `#screenshotFile-${type}-${this.props.activeConsult.consultationId}`,
      );
      inputTag.value = '';
      this.setState({
        uploadingScreenshot: true,
      });
      const onUploadProgress = (progressEvent) => {
        if (progressEvent.lengthComputable) {
          const { loaded, total } = progressEvent;
          this.setState({
            uploadProgress: (loaded / total) * 100,
          });
        } else {
          this.setState({
            uploadProgress: 100,
          });
        }
      };
      const formData = new FormData();
      formData.append('file', file);
      let promise = () => {
        return videoScreenshotByConsultationId(
          formData,
          this.props.activeConsult.consultationId,
          type,
          onUploadProgress,
        );
      };
      if (type === 'paytm') {
        promise = () => {
          return uploadScreenshotByUserId(
            formData,
            this.props.activeConsult.user.userId,
            type,
            onUploadProgress,
          );
        };
      }
      promise()
        .then(() => {
          if (type === 'full') {
            this.setState({
              uploadSuccess2: true,
            });
          } else if (type === 'paytm') {
            this.setState({
              uploadSuccess3: true,
            });
          } else {
            this.setState({
              uploadSuccess: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.setState({
            uploadingScreenshot: false,
            uploadProgress: 0,
          });
        });
    }
  };

  handleAudioUpload = (ev) => {
    const files = ev.target.files;
    console.log(files);
    if (files[0]) {
      const file = files[0];

      const inputTag = document.querySelector(
        `#audioFile-${this.props.activeConsult.user.userId}`,
      );
      inputTag.value = '';
      this.setState({
        uploadingAudio: true,
      });
      const onUploadProgress = (progressEvent) => {
        if (progressEvent.lengthComputable) {
          const { loaded, total } = progressEvent;
          this.setState({
            uploadProgress: (loaded / total) * 100,
          });
        } else {
          this.setState({
            uploadProgress: 100,
          });
        }
      };
      const formData = new FormData();
      formData.append('file', file);
      addAudioByUserId(
        formData,
        this.props.activeConsult.user.userId,
        onUploadProgress,
      )
        .then(() => {
          this.setState({
            uploadAudioSuccess: true,
          });
          this.fetchCallLogs();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.setState({
            uploadingAudio: false,
            uploadProgress: 0,
          });
        });
    }
  };

  handleTranscriptUpload = (ev) => {
    const files = ev.target.files;
    console.log(files);
    if (files[0]) {
      const file = files[0];

      const inputTag = document.querySelector(
        `#transcriptFile-${this.props.activeConsult.user.userId}`,
      );
      inputTag.value = '';
      this.setState({
        uploadingTranscript: true,
      });
      const onUploadProgress = (progressEvent) => {
        if (progressEvent.lengthComputable) {
          const { loaded, total } = progressEvent;
          this.setState({
            uploadProgress: (loaded / total) * 100,
          });
        } else {
          this.setState({
            uploadProgress: 100,
          });
        }
      };
      const formData = new FormData();
      formData.append('file', file);
      addTranscriptByUserId(
        formData,
        this.props.activeConsult.user.userId,
        onUploadProgress,
      )
        .then(() => {
          this.setState({
            uploadTrascriptSuccess: true,
          });
          this.fetchCallLogs();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.setState({
            uploadingTranscript: false,
            uploadProgress: 0,
          });
        });
    }
  };

  updateLanguage = (ucid = null, languageProp) => {
    const { activeConsult } = this.props;
    const { callRemarks, activeCallLogTab } = this.state;
    let languageSelect, language;
    if (this.state.language) {
      languageSelect = this.state.languageSelect;
      language = languageSelect ? this.state.language : this.state.language;
    }
    if (languageProp) {
      languageSelect = languageProp;
      language = languageProp;
    }

    console.log(
      this.state.languageSelect,
      this.state.language,
      languageProp,
      'selectedOption',
    );

    const userId = activeConsult.user.userId;
    const body = {
      language,
      userId,
    };

    updateLanguage(body)
      .then(() => {
        this.setState(
          {
            languageUpdated: true,
          },
          () => {
            if (ucid) {
              setTimeout(() => {
                this.setState({
                  languageUpdated: false,
                  language: '',
                  languageSelect: false,
                });
              }, 600);
            }
          },
        );
      })
      .then(() => {
        if (ucid) {
          callRemarks[ucid] = {
            ...callRemarks[ucid],
            textBoxRemarks: language,
          };
          this.setState(
            {
              callRemarks: Object.assign({}, callRemarks),
              savingRemarks: true,
              savingRemarksFor: ucid,
            },
            () => {
              console.log(callRemarks);
              return saveRemarksForCall(
                ucid,
                callRemarks[ucid].value,
                language,
                activeCallLogTab,
                userId,
              ).finally(() => {
                this.setState({
                  savingRemarks: false,
                  savingRemarksFor: '',
                });
              });
            },
          );
          console.log('done');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleLanguageChange = (selectedOption, ucid) => {
    console.log(selectedOption, 'check');
    // console.log(selectedOption.value);
    //const newRemarkVal = selectedOption.value + this.state.remarks.textBoxRemarks;
    this.setState({
      language: selectedOption.value,
    });
    if (ucid) {
      this.updateLanguage(ucid, selectedOption.value);
    }

    //this.setState({newRemarkVal: selectedOption.value + this.state.remarks.textBoxRemarks });
  };

  handleAssistedChange = (selectedOption) => {
    console.log(selectedOption, 'check');
    const activeConsult = this.props.activeConsult;
    this.setState({
      savingForm: true,
    });
    // console.log(selectedOption.value);
    //const newRemarkVal = selectedOption.value + this.state.remarks.textBoxRemarks;
    if (activeConsult && activeConsult.user) {
      const userId = activeConsult.user.userId;
      const body = {
        userId,
        assisted: selectedOption.value,
      };
      updateAssisted(body)
        .then((res) => {
          console.log(res);
          this.setState({
            savingForm: false,
          });
          message.info('Updated');
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            savingForm: false,
          });
        });
    }

    //this.setState({newRemarkVal: selectedOption.value + this.state.remarks.textBoxRemarks });
  };

  handleVideoUpload = (ev) => {
    const files = ev.target.files;
    console.log(files);
    if (files[0]) {
      const file = files[0];
      const inputTag = document.querySelector(
        `#videoFile-${this.props.activeConsult.consultationId}`,
      );
      inputTag.value = '';
      this.setState({
        uploadingVideo: true,
      });
      const onUploadProgress = (progressEvent) => {
        if (progressEvent.lengthComputable) {
          const { loaded, total } = progressEvent;
          this.setState({
            uploadProgress: (loaded / total) * 100,
          });
        } else {
          this.setState({
            uploadProgress: 100,
          });
        }
      };
      const formData = new FormData();
      formData.append('file', file);
      addVideoByConsultationId(
        formData,
        this.props.activeConsult.consultationId,
        onUploadProgress,
      )
        .then(() => {
          this.setState({
            uploadVideoSuccess: true,
          });
          this.fetchCallLogs();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.setState({
            uploadingVideo: false,
            uploadProgress: 0,
          });
        });
    }
  };

  findCategoryLabel = (categoryId) => {
    let label = '';
    const { callRemarksOptions } = this.state;
    callRemarksOptions.forEach((e) => {
      if (e.categoryId === categoryId) {
        label = e.label;
      }
    });
    console.log(label, 'working ?');
    return label;
  };

  confirmFollowup = () => {
    this.setState({
      showFollowupModal: true,
      followupDate: moment().add(60, 'mins').toDate(),
    });
  };

  onFollowupDateChanged = (date) => {
    this.setState({
      followupDate: date,
    });
  };

  setFollowup = () => {
    const { activeConsult, dispatch } = this.props;
    const { followupDate, callRemarks, activeCallLogTab } = this.state;
    markFollowupUser(
      activeConsult.user.userId,
      moment(followupDate).format('YYYY-MM-DD HH:mm:ss'),
    )
      .then(() => {
        callRemarks[this.state.showFollowupModal] = {
          ...this.state.followupRemark,
          textBoxRemarks: `Call At ${moment(followupDate).format(
            'MMMM Do YYYY, h:mm a',
          )}`,
        };
        this.setState(
          {
            callRemarks: Object.assign({}, callRemarks),
            savingRemarks: true,
            savingRemarksFor: this.state.showFollowupModal,
          },
          () => {
            console.log(callRemarks);
            return saveRemarksForCall(
              this.state.showFollowupModal,
              callRemarks[this.state.showFollowupModal].value,
              callRemarks[this.state.showFollowupModal].textBoxRemarks,
              activeCallLogTab,
              activeConsult.user.userId,
            ).finally(() => {
              this.setState({
                savingRemarks: false,
                savingRemarksFor: '',
              });
            });
          },
        );
        console.log('done');
      })
      .then(() => {
        console.log('done2', this.state.showFollowupModal);
      })
      .then(() => {
        this.hideModal();
        // dispatch(resetActiveConsult());
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          voiceCallLoading: false,
        });
      });
  };

  openAllRecording = (sessionId) => {
    this.setState(
      {
        sessionId: sessionId,
        showSelectVideoModal: true,
      },
      // () => this.getVideoDetail(),
    );
    console.log('ses', this.state.sessionId);
  };

  toggleDiscrepancy = () => {
    const { activeConsult } = this.props;
    const userId = activeConsult.user.userId;

    let flagval = this.state.discrepancyval ? 1 : 0;

    const body = {
      flag: flagval,
      userId,
    };

    toggleDiscrepancyService(body)
      .then((toggleRes) => {
        console.log('value of ', toggleRes, 'flagvalbool', Boolean(flagval));
        this.setState({ discrepancyval: !Boolean(flagval) });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getDiscrepency() {
    const { activeConsult } = this.props;
    const userId = activeConsult.user.userId;

    const descrepencyBody = {
      insurer: 'ipru',
      userId,
    };
    getDiscrepencyService(descrepencyBody)
      .then((result) => {
        const discrepancyval = result.data[0].discrepency_case;

        if (discrepancyval === null || parseInt(discrepancyval) === 0) {
          this.setState({ discrepancyval: true });
        } else {
          this.setState({ discrepancyval: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getFormVal() {
    const { activeConsult } = this.props;
    const userId = activeConsult.user.userId;

    const formBody = {
      insurer: 'ipru',
      userId,
    };
    getFormValService(formBody)
      .then((result) => {
        const isFormAvail = result.data[0].show_form;

        if (isFormAvail === null || parseInt(isFormAvail) === 0) {
          this.setState({ enableForm: true });
        } else {
          this.setState({ enableForm: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  toggleForm = () => {
    const { activeConsult } = this.props;
    const userId = activeConsult.user.userId;

    let flagval = this.state.enableForm ? 1 : 0;

    const body = {
      flag: flagval,
      userId,
    };

    toggleFormService(body)
      .then((toggleRes) => {
        this.setState({ enableForm: !Boolean(flagval) });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  jointMerToggle = () => {
    const { activeConsult } = this.props;
    const userId = activeConsult.user.userId;

    let flagval = this.state.jointMER ? 1 : 0;

    const body = {
      flag: flagval,
      userId,
    };

    jointMerAegonToggle(body)
      .then((toggleRes) => {
        this.setState({ jointMER: !Boolean(flagval) });
        this.getFormQuestions();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  recordingViewInitiated = (url, data) => {
    if (this.props.active || this.props.admin) {
      this.setState({
        selectedRecordingUrl: { url, type: data.type, id: data.callId },
        previousSelectedCallInfo: { url, type: data.type, id: data.callId },
      });
    } else {
      return showError({
        data: {
          errorMessage: 'Please mark yourself active first',
        },
      });
    }
  };
  handleDraggablePlayerControl = (event, data, info) => {
    console.log(info.target.duration);
    const { activeConsult } = this.props;

    markRecordingViewInitiated({
      userId: activeConsult.user.userId,
      isRecordingStarted: this.state.recordingStarted,
      event,
      type: this.state.selectedRecordingUrl
        ? this.state.selectedRecordingUrl.type
        : this.state.previousSelectedCallInfo.type,
      callId: this.state.selectedRecordingUrl
        ? this.state.selectedRecordingUrl.id
        : this.state.previousSelectedCallInfo.id,
      callDuration:
        info && info.target && info.target.duration
          ? info.target.duration
          : null,
    })
      .then(() => {
        if (event === 'start') {
          this.setState({ recordingStarted: true });
        }
        return;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // getVideoDetail = () => {
  //   getVideoDetail(this.props.authToken, this.state.sessionId)
  //     .then((res) => {
  //       console.log(res);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  hideDraggablePlayer = () => {
    setTimeout(() => this.setState({ selectedRecordingUrl: null }), 1000);
  };
  render() {
    const {
      activeConsult,
      admin,
      doctorType,
      userId,
      relatives,
      proposerDetails,
      requestType,
      insurer,
      qc,
      oneConnectLinks,
      vendorId,
    } = this.props;
    const {
      activeTab,
      callRemarks,
      callRemarksOptions,
      callLogs,
      formPreviewed,
      phoneUpdated,
      savingForm,
      savingRemarks,
      savingRemarksFor,
      showPreviewModal,
      previewReport,
      reportViewed,
      activeCallLogTab,
      showFollowupModal,
      followupDate,
      discrepancyval,
      optVal,
      enableForm,
      languageUpdated,
      languageSelect,
      followupRemark,
      language,
      newRemarkVal,
    } = this.state;
    const now = new Date();
    now.setSeconds(0);
    now.setMilliseconds(0);
    console.log(doctorType);
    const renderCallLog = (ele) => {
      console.log('ele', ele);
      const startTime = moment(ele.initiatedAt);
      const remarks = callRemarks[ele.ucid || ele.callId];
      console.log(remarks);
      if (!ele.sessionId && vendorId && vendorId !== ele.vendorId) {
        return false;
      }
      return (
        <CallLogCard
          className="card mt-3"
          key={`${ele.ucid || ele.callId}`}
          status={ele.status}
        >
          <div
            className={`card-header d-flex justify-content-between ${
              ele.status === 'unanswered'
                ? 'bg-danger'
                : ele.status === 'answered'
                ? 'bg-success'
                : 'bg-warning'
            }`}
          >
            <div>
              <Text fontSize="14px" color="white">
                Call at {startTime.format('DD/MM/YYYY HH:mm')}{' '}
              </Text>{' '}
              <Text fontSize="12px" color="white">
                Call By -{' '}
                <BoldText fontSize="12px" color="white" as="span">
                  {' '}
                  {ele.doctorName}{' '}
                </BoldText>{' '}
              </Text>{' '}
            </div>{' '}
            {activeConsult.callType === 'video' ? (
              ''
            ) : (
              <BoldText fontSize="14px" color="white">
                {' '}
                {ele.status === 'unanswered'
                  ? 'UNANSWERED'
                  : ele.status === 'answered'
                  ? 'ANSWERED'
                  : 'UNKNOWN'}{' '}
              </BoldText>
            )}{' '}
          </div>{' '}
          <div className="card-body">
            {' '}
            {ele.sessionId && (
              <RecordingButton
                onTap={() => this.openAllRecording(ele.sessionId)}
              >
                <BoldText fontSize="12px" color="#8852CC">
                  Check All Recorded Videos
                </BoldText>
              </RecordingButton>
            )}
            {ele.audioRecordingFile || ele.videoRecordingFile ? (
              <a
                href={ele.audioRecordingFile || ele.videoRecordingFile}
                onClick={(e) => {
                  e.preventDefault();
                  return false;
                }}
              >
                <RecordingButton
                  onTap={() =>
                    this.recordingViewInitiated(
                      ele.audioRecordingFile || ele.videoRecordingFile,
                      ele,
                    )
                  }
                >
                  <BoldText fontSize="12px"> Click to view Recording </BoldText>{' '}
                </RecordingButton>
              </a>
            ) : (
              // </a>
              <RecordingButton onTap={this.refetchCallRecording(ele.ucid)}>
                <BoldText className="text-warning" fontSize="12px">
                  Retrieve Call Recording{' '}
                </BoldText>{' '}
              </RecordingButton>
            )}{' '}
            {ele.screenshot && (
              <RecordingButton onTap={this.viewRecordingFile(ele.screenshot)}>
                <BoldText fontSize="12px"> Click to view screenshot </BoldText>{' '}
              </RecordingButton>
            )}{' '}
            {ele.screenshot2 && (
              <RecordingButton onTap={this.viewRecordingFile(ele.screenshot2)}>
                <BoldText fontSize="12px">
                  Click to view full length screenshot{' '}
                </BoldText>{' '}
              </RecordingButton>
            )}{' '}
            <BoldText fontSize="12px" color="#72777A" className="mt-3">
              Add Call Remarks{' '}
            </BoldText>{' '}
            {insurer === 'ipru' ? (
              <div>
                <Select
                  options={callRemarksOptions.map((ele) => ({
                    label: ele.label,
                    value: ele.categoryId,
                    categoryId: ele.categoryId,
                    categoryLabel: ele.label,
                  }))}
                  // isDisabled={qc}
                  classNamePrefix="react-select"
                  value={
                    remarks.categoryId
                      ? {
                          label: this.findCategoryLabel(remarks.categoryId),
                          value: remarks.categoryId,
                        }
                      : null
                  }
                  onChange={this.setRemarksForCall(
                    ele.ucid || ele.callId,
                    true,
                  )}
                  className="mt-2 select-options"
                />{' '}
                {remarks.categoryId ? (
                  <Select
                    options={callRemarksOptions.filter((ele) => {
                      if (ele.categoryId === remarks.categoryId) {
                        console.log(ele);
                        return ele.options;
                      }
                    })}
                    // isDisabled={qc}
                    classNamePrefix="react-select"
                    value={
                      remarks.label
                        ? {
                            label: remarks.label,
                            value: remarks.value,
                          }
                        : null
                    }
                    onChange={this.setRemarksForCall(ele.ucid || ele.callId)}
                    className="mt-2 select-options"
                  />
                ) : (
                  ''
                )}{' '}
              </div>
            ) : (
              <Select
                options={callRemarksOptions}
                // isDisabled={qc}
                classNamePrefix="react-select"
                value={
                  remarks.label
                    ? {
                        label: remarks.label,
                        value: remarks.value,
                      }
                    : null
                }
                onChange={this.setRemarksForCall(ele.ucid || ele.callId)}
                className="mt-2 select-options"
              />
            )}{' '}
            {languageSelect && (
              <Select
                placeholder="Please Select a Language"
                onChange={(e) => {
                  console.log(e);
                  this.handleLanguageChange(e, ele.ucid || ele.callId);
                }}
                className="mt-3 mr-2"
                options={this.state.languageOptions}
              />
            )}
            <input
              className="form-control mt-3 p-2"
              type="text"
              placeholder={`Remarks for above reason ${
                remarks.remarksRequired ? '(Mandatory)' : ''
              }`}
              value={remarks.textBoxRemarks || ''}
              onChange={this.setCallTextBoxRemarks(ele.ucid || ele.callId)}
              // disabled={qc}
            />{' '}
            <PrimaryButton
              type="button"
              className="mt-3 float-left"
              onTap={this.saveRemarksForCall(
                ele.ucid || ele.callId,
                activeConsult.callType,
                activeConsult &&
                  activeConsult.user &&
                  activeConsult.user.userId,
              )}
              disabled={
                // qc ||
                remarks === null ||
                remarks === undefined ||
                !remarks.label ||
                savingRemarks ||
                (remarks.remarksRequired && !remarks.textBoxRemarks)
              }
            >
              <Text fontSize="16px" color="white">
                {' '}
                {savingRemarksFor === ele.ucid ? 'Saving' : 'Save Remarks'}{' '}
              </Text>{' '}
            </PrimaryButton>{' '}
            {/* <div className="mt-4" />
                              <BoldText fontSize="12px" color="#72777A">
                                Events
                              </BoldText>
                              <div className="card mt-2">
                                <ul className="list-group list-group-flush">
                                  {ele.events.map((ev) => {
                                    const eventTime = moment(ev.eventAt);
                                    return (
                                      <li className="list-group-item d-flex justify-content-between">
                                        <BoldText fontSize="12px" color="#242327">
                                          {ev.eventType}
                                        </BoldText>
                                        <Text fontSize="12px" color="#56595B">
                                          {eventTime.format('HH:mm')}
                                        </Text>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div> */}{' '}
          </div>{' '}
        </CallLogCard>
      );
    };
    const returnColorForWhatsappStatus = (status) => {
      switch (status.toLowerCase()) {
        case 'delivered':
          return '#7FBA7A';
        case 'read':
          return '#1EB865';
        case 'failed':
          return '#E45252';
        case 'submitted':
        default:
          return '#FFCE73';
      }
    };
    console.log(activeConsult.consultationId);
    const renderActiveTab = () => {
      if (!activeConsult.consultationId) {
        return (
          <OuterContainer>
            <BodyContainer>
              <BoldText fontSize="14px">
                Please select a consultation to view details{' '}
              </BoldText>{' '}
            </BodyContainer>{' '}
          </OuterContainer>
        );
      }
      switch (activeTab) {
        case 'callLogs':
          return (
            <div className="container">
              {' '}
              {activeConsult.callType === 'video' && (
                <TabContainer>
                  <TabItemContainer
                    active={activeCallLogTab === 'voice'}
                    onTap={() => {
                      this.setState({
                        activeCallLogTab: 'voice',
                      });
                    }}
                  >
                    {' '}
                    {activeCallLogTab === 'voice' ? (
                      <TabActiveItemText> Voice </TabActiveItemText>
                    ) : (
                      <TabItemText> Voice </TabItemText>
                    )}{' '}
                  </TabItemContainer>{' '}
                  <TabItemContainer
                    active={activeCallLogTab === 'video'}
                    onTap={() => {
                      this.setState({
                        activeCallLogTab: 'video',
                      });
                    }}
                  >
                    {' '}
                    {activeCallLogTab === 'video' ? (
                      <TabActiveItemText> Video </TabActiveItemText>
                    ) : (
                      <TabItemText> Video </TabItemText>
                    )}{' '}
                  </TabItemContainer>{' '}
                </TabContainer>
              )}{' '}
              <div className="row">
                <div className="col-12 pt-3">
                  <BoldText fontSize="16px" color="#242327">
                    Call Logs{' '}
                  </BoldText>{' '}
                </div>{' '}
                {callLogs.length > 0 ? (
                  <div className="col-12"> {callLogs.map(renderCallLog)} </div>
                ) : (
                  <div className="col-12 text-center mt-3">
                    <h3>
                      No Call Logs Found.Initiate a call to view logs here.{' '}
                    </h3>{' '}
                  </div>
                )}{' '}
              </div>{' '}
            </div>
          );
        case 'script':
          console.log(proposerDetails, 'proposerDetails');
          if (proposerDetails && proposerDetails.proposerFormUrl) {
            return (
              <iframe
                title="MER Script"
                src={proposerDetails.proposerFormUrl}
                style={{
                  height: '100%',
                }}
              />
            );
          }
          switch (insurer) {
            case 'abhi':
              return (
                <iframe
                  title="MER Script"
                  src="https://visit-public.s3.ap-south-1.amazonaws.com/mer/abhi-script.pdf"
                  style={{
                    height: '100%',
                  }}
                />
              );
            case 'ipru':
              return (
                <iframe
                  title="MER Script"
                  src="https://visit-public.s3.ap-south-1.amazonaws.com/mer/new-script/ipru-script.pdf"
                  style={{
                    height: '100%',
                  }}
                />
              );
            case 'max':
              return (
                <iframe
                  title="MER Script"
                  src="https://visit-public.s3.ap-south-1.amazonaws.com/mer/new-script/max-bupa.pdf"
                  style={{
                    height: '100%',
                  }}
                />
              );
            case 'aegon':
              return (
                <iframe
                  title="MER Script"
                  src="https://visit-public.s3.ap-south-1.amazonaws.com/mer/BANDHAN+LIFE+TELE+SCRIPT.docx.pdf"
                  style={{
                    height: '100%',
                  }}
                />
              );
            case 'ppmc':
              return (
                <iframe
                  title="MER Script"
                  src="https://visit-public.s3.ap-south-1.amazonaws.com/mer/new-script/ppmc-script.pdf"
                  style={{
                    height: '100%',
                  }}
                />
              );
            case 'lic':
              return (
                <iframe
                  title="MER Script"
                  src="https://visit-public.s3.ap-south-1.amazonaws.com/mer/new-script/lic-script.pdf"
                  style={{
                    height: '100%',
                  }}
                />
              );
            case 'reliance':
              return (
                <iframe
                  title="MER Script"
                  src="https://visit-public.s3.ap-south-1.amazonaws.com/mer/new-script/reliance-script.pdf"
                  style={{
                    height: '100%',
                  }}
                />
              );
            case 'godigit':
              return (
                <iframe
                  title="MER Script"
                  src="https://visit-public.s3.ap-south-1.amazonaws.com/mer/new-script/reliance-script.pdf"
                  style={{
                    height: '100%',
                  }}
                />
              );
            case 'tata':
              return (
                <iframe
                  title="MER Script"
                  src="https://visit-public.s3.ap-south-1.amazonaws.com/mer/tata-script.pdf"
                  style={{
                    height: '100%',
                  }}
                />
              );

            case 'absli':
              return (
                <iframe
                  title="MER Script"
                  src="https://visit-public.s3.ap-south-1.amazonaws.com/mer/new-script/absli-tele-script.pdf"
                  style={{
                    height: '100%',
                  }}
                />
              );
            case 'absli-vv':
              return (
                <iframe
                  title="MER Script"
                  src="https://visit-public.s3.ap-south-1.amazonaws.com/mer/new-script/absli-tele-script.pdf"
                  style={{
                    height: '100%',
                  }}
                />
              );
            case 'mchi':
              return (
                <iframe
                  title="MER Script"
                  src="https://visit-public.s3.ap-south-1.amazonaws.com/mer/new-script/mchi-script.pdf"
                  style={{
                    height: '100%',
                  }}
                />
              );
            default:
              return (
                <iframe
                  title="MER Script"
                  src="https://visit-public.s3.ap-south-1.amazonaws.com/mer/new-script/max-bupa.pdf"
                  style={{
                    height: '100%',
                  }}
                />
              );
          }
        case 'profile':
        default:
          return (
            <>
              {' '}
              {requestType === 'voice' && (
                <NoteText>
                  <CallIcon />
                  User has requested for a call{' '}
                </NoteText>
              )}{' '}
              {admin && doctorType === 'mbbs' && !qc && (
                <NoteText>
                  <div className="form-row">
                    <div className="col-7">
                      <BoldText fontSize="14px"> Update Phone Number </BoldText>{' '}
                      <input
                        className="form-control border "
                        type="text"
                        onChange={(e) => {
                          this.setState({
                            updatedNumber: e.target.value,
                          });
                        }}
                        id="updated-phone-input"
                      />
                      <PreviewButton
                        loading={savingForm}
                        onTap={() => this.updatePhone()}
                        disabled={String(this.state.updatedNumber).length < 6}
                        className="mt-3 mr-2"
                      >
                        <Text fontSize="16px" color="white">
                          Update number{' '}
                        </Text>{' '}
                      </PreviewButton>{' '}
                      {['max', 'mchi', 'reliance'].includes(insurer) ===
                      true ? (
                        <PreviewButton
                          loading={savingForm}
                          onTap={() => this.updatePhone(true)}
                          disabled={String(this.state.updatedNumber).length < 6}
                          className="mt-3 mr-2"
                        >
                          <Text fontSize="16px" color="white">
                            Update Alternate number{' '}
                          </Text>{' '}
                        </PreviewButton>
                      ) : (
                        ''
                      )}
                    </div>{' '}
                    {phoneUpdated && (
                      <div className="col-8">
                        <Text fontSize="14px" className="text-success">
                          Updated successfully{' '}
                        </Text>{' '}
                      </div>
                    )}{' '}
                  </div>{' '}
                </NoteText>
              )}{' '}
              {(insurer === 'max' || insurer === 'ipru') &&
                !qc &&
                activeConsult &&
                activeConsult.user &&
                activeConsult.user.userId && (
                  <NoteText>
                    <div className="form-row">
                      <div className="col-7">
                        <BoldText fontSize="14px">
                          TU Incomplete Remark{' '}
                        </BoldText>{' '}
                        <input
                          className="form-control border "
                          type="text"
                          onChange={(e) => {
                            this.setState({
                              tuIncompleteRemark: e.target.value,
                            });
                          }}
                          id="tu-incomplete-input"
                        />
                        <PreviewButton
                          loading={savingForm}
                          onTap={this.markTuIncomplete}
                          disabled={
                            String(this.state.tuIncompleteRemark).length < 6
                          }
                          className="mt-3 mr-2"
                        >
                          <Text fontSize="16px" color="white">
                            MARK TU INCOMPLETE{' '}
                          </Text>{' '}
                        </PreviewButton>{' '}
                      </div>{' '}
                      {this.state.tuIncompleteMarked && (
                        <div className="col-8">
                          <Text fontSize="14px" className="text-success">
                            TU Marked Incomplete{' '}
                          </Text>{' '}
                        </div>
                      )}{' '}
                    </div>{' '}
                  </NoteText>
                )}{' '}
              {qc &&
                activeConsult &&
                activeConsult.user &&
                activeConsult.user.userId && (
                  <NoteText>
                    <div className="form-row">
                      <div className="col-7">
                        <BoldText fontSize="14px">ON HOLD Remark </BoldText>{' '}
                        <input
                          className="form-control border "
                          type="text"
                          onChange={(e) => {
                            this.setState({
                              tuIncompleteRemark: e.target.value,
                            });
                          }}
                          id="tu-incomplete-input"
                        />
                        <PreviewButton
                          loading={savingForm}
                          onTap={this.markTuIncomplete}
                          disabled={
                            String(this.state.tuIncompleteRemark).length < 6
                          }
                          className="mt-3 mr-2"
                        >
                          <Text fontSize="16px" color="white">
                            MARK ON HOLD{' '}
                          </Text>{' '}
                        </PreviewButton>{' '}
                      </div>{' '}
                      {this.state.tuIncompleteMarked && (
                        <div className="col-8">
                          <Text fontSize="14px" className="text-success">
                            MARKED ON HOLD
                          </Text>{' '}
                        </div>
                      )}{' '}
                    </div>{' '}
                  </NoteText>
                )}{' '}
              {true && !qc ? (
                <div>
                  <div className="col-7">
                    <Select
                      placeholder="Please Select a Language"
                      onChange={(e) => {
                        this.handleLanguageChange(e);
                      }}
                      className="mt-3 mr-2"
                      options={this.state.languageOptions}
                    />
                    <PreviewButton
                      loading={savingForm}
                      onTap={() => this.updateLanguage()}
                      disabled={String(this.state.language).length < 1}
                      className="mt-3 mr-2"
                    >
                      <Text fontSize="16px" color="white">
                        Update Language{' '}
                      </Text>{' '}
                    </PreviewButton>{' '}
                  </div>
                  {languageUpdated && (
                    <div className="col-8">
                      <Text fontSize="14px" className="text-success">
                        Language Updated successfully{' '}
                      </Text>{' '}
                    </div>
                  )}{' '}
                </div>
              ) : (
                ''
              )}
              {(insurer === 'ipru' || (insurer === 'reliance' && admin)) &&
                activeConsult &&
                activeConsult.user &&
                activeConsult.user.userId && (
                  <NoteText>
                    <div className="form-row">
                      <div className="col-7">
                        <BoldText fontSize="14px">Mark Duplicate:</BoldText>{' '}
                        <input
                          className="form-control border "
                          type="text"
                          placeholder="Enter Application Id of Duplicate Case"
                          onChange={(e) => {
                            this.setState({
                              duplicateRemark: e.target.value,
                            });
                          }}
                          id="tu-incomplete-input"
                        />
                        <PreviewButton
                          loading={savingForm}
                          onTap={this.markDuplicate}
                          disabled={
                            String(this.state.duplicateRemark).length < 5
                          }
                          className="mt-3 mr-2"
                        >
                          <Text fontSize="16px" color="white">
                            MARK DUPLICATE{' '}
                          </Text>{' '}
                        </PreviewButton>{' '}
                      </div>{' '}
                      {this.state.duplicateMarked && (
                        <div className="col-8">
                          <Text fontSize="14px" className="text-success">
                            Added Recording From Duplicate Case{' '}
                          </Text>{' '}
                        </div>
                      )}{' '}
                    </div>{' '}
                  </NoteText>
                )}{' '}
              <div className="">
                {activeConsult &&
                  !qc &&
                  (insurer === 'max' ||
                    insurer === 'absli-vv' ||
                    insurer === 'absli' ||
                    insurer === 'aegon' ||
                    insurer === 'ppmc' ||
                    insurer === 'ipru') &&
                  !activeConsult.closedAt && (
                    <div className="row ml-1 mr-1">
                      <div className="col-7">
                        <PreviewButton
                          loading={savingForm}
                          onTap={this.smsBeforeAttempt}
                          className="mt-3 mr-2"
                          disabled={this.state.smsSent}
                        >
                          <Text fontSize="16px" color="white">
                            {' '}
                            {!this.state.smsSent ? 'Send SMS' : 'SMS sent'}{' '}
                          </Text>{' '}
                        </PreviewButton>{' '}
                      </div>{' '}
                    </div>
                  )}{' '}
                {insurer === 'aegon' ? (
                  <div>
                    <div className="col-7">
                      <Select
                        disabled={activeConsult.qcApprovedAt !== null}
                        placeholder="Select Assited / Not Assisted Status"
                        onChange={(e) => {
                          this.handleAssistedChange(e);
                        }}
                        value={
                          proposerDetails && proposerDetails.assisted
                            ? {
                                label: proposerDetails.assisted,
                                value: proposerDetails.assisted,
                              }
                            : ''
                        }
                        className="mt-3 mr-2"
                        options={[
                          {
                            value: 'Assisted',
                            label: 'Assisted',
                          },
                          {
                            value: 'Not Assisted',
                            label: 'Not Assisted',
                          },
                        ]}
                      />
                    </div>
                    <hr />
                  </div>
                ) : (
                  ''
                )}
                {admin && !activeConsult.rejectedAt && !vendorId && !qc && (
                  <div className="row ml-1 mr-1">
                    <div className="col-7">
                      <PreviewButton
                        loading={savingForm}
                        onTap={this.rejectCase}
                        className="mt-3 mr-2"
                      >
                        <Text fontSize="16px" color="white">
                          Reject Case{' '}
                        </Text>{' '}
                      </PreviewButton>{' '}
                    </div>{' '}
                  </div>
                )}{' '}
                {admin &&
                  insurer === 'ipru' &&
                  proposerDetails &&
                  !proposerDetails.closedAt &&
                  !vendorId &&
                  !qc && (
                    <div className="row ml-1 mr-1">
                      <div className="col-7">
                        <PreviewButton
                          loading={savingForm}
                          onTap={() => this.rejectCase(true)}
                          className="mt-3 mr-2"
                        >
                          <Text fontSize="16px" color="white">
                            Mark DND{' '}
                          </Text>{' '}
                        </PreviewButton>{' '}
                      </div>{' '}
                    </div>
                  )}
                {activeConsult && activeConsult.user && insurer === 'ipru' && (
                  <div className="row ml-1 mr-1">
                    <div className="col-7">
                      <PreviewButton
                        loading={this.state.loading}
                        onTap={this.caseCompleted}
                        className="mt-3 mr-2"
                        disabled={this.state.caseCompletedCheck}
                      >
                        <Text fontSize="16px" color="white">
                          Case Completed IN ARMSOFT
                        </Text>{' '}
                      </PreviewButton>{' '}
                    </div>{' '}
                  </div>
                )}
                <div className="d-flex jusitfy-content-evenly col">
                  {activeConsult &&
                    (insurer === 'ipru' ||
                      insurer === 'abhi' ||
                      insurer === 'lic' ||
                      insurer === 'ppmc' ||
                      (insurer === 'aegon' &&
                        (activeConsult.requirementCode === 'VIDEOTELEMER' ||
                          activeConsult.requirementCode === 'ZOOPER'))) &&
                    !this.state.convertToTele &&
                    admin &&
                    !qc && (
                      <div className="ml-1 mr-1">
                        <div className="">
                          <PreviewButton
                            loading={savingForm}
                            onTap={() =>
                              this.convertToTele(
                                activeConsult.user.userId,
                                activeConsult.callType === 'voice'
                                  ? 'video'
                                  : 'voice',
                              )
                            }
                            className="mt-3 mr-2"
                          >
                            <Text fontSize="16px" color="white">
                              Convert To{' '}
                              {activeConsult.callType === 'voice'
                                ? 'Video'
                                : 'Tele'}{' '}
                            </Text>{' '}
                          </PreviewButton>{' '}
                        </div>{' '}
                      </div>
                    )}{' '}
                  {insurer === 'mchi' &&
                    activeConsult &&
                    proposerDetails &&
                    proposerDetails.questionType !== 'MER-2' &&
                    !this.state.convertToTele &&
                    admin &&
                    !qc && (
                      <div className="ml-1 mr-1">
                        <div className="">
                          <PreviewButton
                            loading={savingForm}
                            onTap={() =>
                              this.convertToTele(
                                activeConsult.user.userId,
                                'PRIME',
                              )
                            }
                            className="mt-3 mr-2"
                          >
                            <Text fontSize="16px" color="white">
                              Convert To SECHLT
                            </Text>
                          </PreviewButton>
                        </div>{' '}
                      </div>
                    )}{' '}
                  {insurer === 'ipru' &&
                    activeConsult &&
                    admin &&
                    !vendorId &&
                    !activeConsult.vendorName &&
                    !qc && (
                      <>
                        {/* <div className="ml-1 mr-1">
                        <div className="">
                          <PreviewButton
                            loading={savingForm}
                            onTap={() =>
                              this.transferToVendor(activeConsult.user.userId)
                            }
                            className="mt-3 mr-2"
                          >
                            <Text fontSize="16px" color="white">
                              Send to vendor
                            </Text>{' '}
                          </PreviewButton>{' '}
                        </div>{' '}
                      </div> */}
                        {proposerDetails && activeConsult.closedAt && !qc ? (
                          <div className="ml-1 mr-1">
                            <div className="col-12">
                              <PreviewButton
                                loading={savingForm}
                                onTap={this.resendCallback}
                                className="mt-3 mr-2"
                              >
                                <Text fontSize="16px" color="white">
                                  Resend Via API
                                </Text>{' '}
                              </PreviewButton>{' '}
                              <br />
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </>
                    )}{' '}
                </div>
              </div>
              {activeConsult.rejectedAt && (
                <div className="row ml-1 mr-1">
                  <div className="col-12">
                    <div className="alert alert-danger">
                      <Text fontSize="14px">
                        This case was marked as rejected.{' '}
                      </Text>{' '}
                    </div>{' '}
                  </div>{' '}
                </div>
              )}{' '}
              {activeConsult.userRejectedAt && (
                <>
                  <div className="row ml-1 mr-1 mt-2">
                    <div className="col-12">
                      <div className="alert alert-danger">
                        <Text fontSize="14px">
                          This case was marked rejected by user. <br /> Remark
                          by user:{' '}
                          <strong> {activeConsult.userRejectionRemark} </strong>{' '}
                          {activeConsult.userRejectedQuestions ? (
                            <>
                              {' '}
                              <br /> User have an issue with these questions:{' '}
                              <strong>
                                {' '}
                                {activeConsult.userRejectedQuestions}{' '}
                              </strong>{' '}
                            </>
                          ) : (
                            ''
                          )}{' '}
                        </Text>{' '}
                      </div>{' '}
                    </div>{' '}
                    {insurer === 'aegon' ? (
                      <div className="col-7">
                        <PreviewButton
                          loading={savingForm}
                          onTap={this.updateTranscript}
                          className="mt-3 mr-2"
                        >
                          <Text fontSize="16px" color="white">
                            Update Transcript{' '}
                          </Text>{' '}
                        </PreviewButton>{' '}
                      </div>
                    ) : (
                      ''
                    )}{' '}
                  </div>{' '}
                </>
              )}{' '}
              {activeConsult.teleRemarks && (
                <div className="row ml-1 mr-1 mt-2">
                  <div className="col-12">
                    <div className="alert alert-success">
                      <Text fontSize="14px">
                        Tele Remarks by insurer:
                        <strong> {activeConsult.teleRemarks} </strong>{' '}
                      </Text>{' '}
                    </div>{' '}
                  </div>{' '}
                </div>
              )}{' '}
              {activeConsult.onHoldRemark && (
                <div className="row ml-1 mr-1 mt-2">
                  <div className="col-12">
                    <div className="alert alert-success">
                      <Text fontSize="14px">
                        ON HOLD REMARKS:
                        <strong> {activeConsult.onHoldRemark} </strong>{' '}
                      </Text>{' '}
                    </div>{' '}
                  </div>{' '}
                </div>
              )}{' '}
              {activeConsult.insurerReinitiatedAt && (
                <div className="row ml-1 mr-1 mt-2">
                  <div className="col-12">
                    <div className="alert alert-danger">
                      <Text fontSize="14px">
                        This case was marked reinitiated by insurer. <br />{' '}
                        Remark by insurer:{' '}
                        <strong>
                          {' '}
                          {activeConsult.insurerReinitiationRemark}{' '}
                        </strong>{' '}
                      </Text>{' '}
                    </div>{' '}
                  </div>{' '}
                </div>
              )}{' '}
              {proposerDetails && proposerDetails.remarks ? (
                <div className="row ml-1 mr-1 mt-2">
                  <div className="col-12">
                    <div className="alert alert-success">
                      <Text fontSize="14px">
                        {insurer === 'ipru'
                          ? 'Discrepancy Remarks: '
                          : 'Grievance Remarks by insurer:'}
                        <strong> {proposerDetails.remarks} </strong>{' '}
                      </Text>{' '}
                    </div>{' '}
                  </div>{' '}
                </div>
              ) : (
                ''
              )}
              {proposerDetails && proposerDetails.reopenRemark && (
                <div className="row ml-1 mr-1 mt-2">
                  <div className="col-12">
                    <div className="alert alert-danger">
                      <Text fontSize="14px">
                        This case was Reopened. <br />
                        QC Remark :{' '}
                        <strong>{proposerDetails.reopenRemark}</strong>
                      </Text>
                    </div>
                  </div>
                </div>
              )}{' '}
              {(insurer === 'aegon' ||
                insurer === 'max' ||
                insurer === 'ipru' ||
                insurer === 'abhi' ||
                insurer === 'mchi' ||
                insurer === 'reliance' ||
                insurer === 'absli' ||
                insurer === 'absli-vv' ||
                insurer === 'godigit' ||
                insurer === 'tata' ||
                insurer === 'ppmc') &&
              qc ? (
                <div>
                  <div
                    className="col-7 pt-1"
                    style={{ display: 'flex', gap: '1rem' }}
                  >
                    <Text fontSize="16px"> Feedback Remarks:</Text>
                    <div style={{ width: '200px' }}>
                      <Select
                        options={FeedbackSelectOptions}
                        // isDisabled={qc}
                        onChange={this.handleChange}
                        value={FeedbackSelectOptions.filter(
                          (option) => option.value === this.state.optVal,
                        )}
                        classNamePrefix="react-select"
                      />{' '}
                    </div>
                  </div>
                  <div className="row ml-1 mr-1 mt-1">
                    <div className="col-7 pt-1">
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="4"
                        value={this.state.feedback}
                        onChange={(e) => {
                          this.setState({
                            feedback: e.target.value,
                          });
                        }}
                        placeholder="Enter Feedback in this section ..."
                      ></textarea>
                      <PreviewButton
                        onTap={this.submitFeedback}
                        className="mt-3 mr-2"
                        disabled={this.state.alertFeedback}
                      >
                        <Text color="white">Feedback Submit </Text>{' '}
                      </PreviewButton>{' '}
                    </div>{' '}
                  </div>{' '}
                </div>
              ) : (
                ''
              )}
              {this.state.alertFeedback ? (
                <NoteText>Feedback has been submitted </NoteText>
              ) : (
                ''
              )}
              {activeConsult.incompleteAt && (
                <div className="row ml-1 mr-1 mt-2">
                  <div className="col-12">
                    <div className="alert alert-danger">
                      <Text fontSize="14px">
                        This case was marked TU INCOMPLETE. <br /> Remark:{' '}
                        <strong> {activeConsult.incompleteRemark} </strong>{' '}
                      </Text>{' '}
                    </div>{' '}
                  </div>{' '}
                </div>
              )}
              {admin &&
              ['abhi', 'absli', 'mchi'].includes(insurer) === true &&
              proposerDetails &&
              proposerDetails.reopenedAt &&
              !proposerDetails.closedAt &&
              proposerDetails.doctorId ? (
                <div className="row ml-1 mr-1 mt-2">
                  <div className="col-7">
                    <PreviewButton
                      loading={savingForm}
                      onTap={this.updateTranscript}
                      className="mt-3 mr-2"
                    >
                      <Text fontSize="16px" color="white">
                        Mark Completed
                      </Text>
                    </PreviewButton>
                  </div>
                </div>
              ) : (
                ''
              )}
              {admin &&
              insurer === 'lic' &&
              proposerDetails &&
              proposerDetails.closedAt ? (
                <div className="row ml-1 mr-1 mt-2">
                  <div className="col-7">
                    <PreviewButton
                      loading={savingForm}
                      onTap={this.updateTranscript}
                      className="mt-3 mr-2"
                    >
                      <Text fontSize="16px" color="white">
                        Mark Completed
                      </Text>
                    </PreviewButton>
                  </div>
                </div>
              ) : (
                ''
              )}
              {insurer === 'ipru' && admin && !qc && false ? (
                <div className="ml-4 mt-4">
                  {discrepancyval ? (
                    <div>
                      <label className="switch">
                        <input
                          type="checkbox"
                          onChange={this.toggleDiscrepancy}
                        />
                        <span className="slider round"></span>
                      </label>
                      <Text>
                        {' '}
                        Discrepancy Toggle - {discrepancyval ? 'NO' : 'YES'}
                      </Text>
                    </div>
                  ) : (
                    <div>
                      <label className="switch">
                        <input
                          type="checkbox"
                          onChange={this.toggleDiscrepancy}
                          checked
                        />
                        <span className="slider round"> </span>
                      </label>
                      <Text>
                        {' '}
                        Discrepancy Toggle - {discrepancyval ? 'NO' : 'YES'}
                      </Text>
                    </div>
                  )}
                </div>
              ) : (
                ''
              )}
              {insurer === 'max' && admin && !this.state.convertOldForm && (
                <div className="row ml-1 mr-1 mt-2">
                  <div className="col-12 pt-2">
                    <PreviewButton
                      onTap={() =>
                        this.convertOldForm(activeConsult.user.userId)
                      }
                    >
                      <Text fontSize="16px" color="white">
                        Convert to Old Form
                      </Text>
                    </PreviewButton>
                  </div>
                </div>
              )}
              {insurer === 'ipru' && admin && !qc ? (
                <div className="ml-4 mt-4">
                  {enableForm ? (
                    <div
                      id="form-line"
                      style={{
                        display: 'inline-block',
                        justifyContent: 'center',
                      }}
                    >
                      <div>
                        <label className="switch">
                          <input
                            type="checkbox"
                            onChange={this.toggleForm}
                            checked={enableForm ? false : true}
                          />
                          <span className="slider round"> </span>
                        </label>
                      </div>
                      <div>
                        <Text>
                          {' '}
                          Show Form - {enableForm ? 'Disabled' : 'Enabled'}
                        </Text>
                      </div>
                    </div>
                  ) : (
                    <div
                      id="form-line"
                      style={{
                        display: 'inline-block',
                        justifyContent: 'center',
                      }}
                    >
                      <div>
                        <label className="switch">
                          <input
                            type="checkbox"
                            onChange={this.toggleForm}
                            checked={enableForm ? false : true}
                          />
                          <span className="slider round"> </span>
                        </label>
                      </div>
                      <div>
                        <Text>
                          {' '}
                          Show Form - {enableForm ? 'Disabled' : 'Enabled'}
                        </Text>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                ''
              )}
              <div className="row ml-1">
                {proposerDetails &&
                proposerDetails.spNumber === 0 &&
                admin &&
                !qc ? (
                  <div className="row ml-1 mr-1 mt-2">
                    <div className="col-12 pt-2">
                      <PreviewButton onTap={this.specialNum}>
                        <Text fontSize="16px" color="white">
                          Special Number
                        </Text>
                      </PreviewButton>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {proposerDetails &&
                insurer === 'max' &&
                !proposerDetails.nightCalling &&
                moment().hours() >= 20 &&
                !qc &&
                admin ? (
                  <div className="row ml-1 mr-1 mt-2">
                    <div className="col-12 pt-2">
                      <PreviewButton onTap={this.allowNightCalling}>
                        <Text fontSize="16px" color="white">
                          Allow Night Calling
                        </Text>
                      </PreviewButton>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              {activeConsult &&
              [477].includes(this.props.doctorId) &&
              !activeConsult.closedAt ? (
                <div className="col-7 pt-2">
                  <PreviewButton
                    loading={savingForm}
                    onTap={this.reopenCase}
                    className="mt-3 mr-2"
                  >
                    <Text fontSize="16px" color="white">
                      Reopen Case{' '}
                    </Text>{' '}
                  </PreviewButton>
                </div>
              ) : (
                ''
              )}
              {insurer === 'ppmc' ? (
                <div className="row ml-2 mr-1">
                  <PreviewButton
                    loading={savingForm}
                    onTap={this.sendTranscript}
                    className="mt-3 mr-2"
                    disabled={this.state.smsSent}
                  >
                    <Text fontSize="16px" color="white">
                      Send Report To User{' '}
                    </Text>{' '}
                  </PreviewButton>{' '}
                </div>
              ) : (
                ''
              )}
              {(admin || qc) &&
                (activeConsult.nonWorkableAt ||
                  activeConsult.rejectedAt ||
                  activeConsult.labels[1].text === 'Completed') && (
                  <div className="row ml-1 mr-1">
                    {activeConsult.labels[1].text === 'Completed' ? (
                      ''
                    ) : (
                      <div className="col-12">
                        {activeConsult.tuAlreadyDone ? (
                          <div className="alert alert-warning">
                            <Text fontSize="14px">
                              This case was marked as{' '}
                              <strong>tu already done.</strong>
                            </Text>{' '}
                          </div>
                        ) : activeConsult.rejectedAt ? (
                          <div className="alert alert-warning">
                            <Text fontSize="14px">
                              This case was marked as <strong>rejected</strong>.
                            </Text>{' '}
                          </div>
                        ) : activeConsult.nonWorkableAt ? (
                          <div className="alert alert-warning">
                            <Text fontSize="14px">
                              This case was marked as non - workable.Please
                              check call remarks for details{' '}
                            </Text>{' '}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    )}
                    {/* <div className="col-12">
                      {' '}
                      {!activeConsult.rejectedAt &&
                        activeConsult.labels[1].text !== 'Completed' && (
                          <div className="alert alert-warning">
                            <Text fontSize="14px">
                              This case was marked as non - workable.Please
                              check call remarks for details{' '}
                            </Text>{' '}
                          </div>
                        )}{' '}
                    </div>{' '} */}
                    {/* <div className="col-12">
                      {' '}
                      {!activeConsult.tuAlreadyDone &&
                        activeConsult.labels[1].text !== 'Completed' && (
                          <div className="alert alert-warning">
                            <Text fontSize="14px">
                              This case was marked as tu already done.
                            </Text>{' '}
                          </div>
                        )}{' '}
                    </div>{' '} */}
                    {!qc ? (
                      <div className="col-7 pt-2">
                        <BoldText fontSize="14px">QC Remark</BoldText>
                        <input
                          className="form-control border"
                          type="text"
                          onChange={(e) => {
                            this.setState({
                              reopenRemark: e.target.value,
                            });
                          }}
                        />
                        <PreviewButton
                          loading={savingForm}
                          onTap={this.reopenCase}
                          className="mt-3 mr-2"
                          disabled={qc && this.state.reopenRemark.length < 4}
                        >
                          <Text fontSize="16px" color="white">
                            Reopen Case{' '}
                          </Text>{' '}
                        </PreviewButton>{' '}
                      </div>
                    ) : (
                      ''
                    )}
                    {insurer === 'max' && activeConsult.closedAt ? (
                      <div className="col-7">
                        <PreviewButton
                          loading={savingForm}
                          onTap={this.reopenForm}
                          className="mt-3 mr-2"
                        >
                          <Text fontSize="16px" color="white">
                            Reopen NIVA FORM{' '}
                          </Text>{' '}
                        </PreviewButton>{' '}
                      </div>
                    ) : (
                      ''
                    )}{' '}
                    {insurer === 'aegon' ||
                    insurer === 'max' ||
                    insurer === 'godigit' ||
                    insurer === 'tata' ||
                    insurer === 'reliance' ? (
                      <div className="col-7">
                        <PreviewButton
                          loading={savingForm}
                          onTap={this.sendTranscript}
                          className="mt-3 mr-2"
                          disabled={this.state.smsSent}
                        >
                          <Text fontSize="16px" color="white">
                            Send Report To User{' '}
                          </Text>{' '}
                        </PreviewButton>{' '}
                      </div>
                    ) : (
                      ''
                    )}
                    {activeConsult.qcApprovedAt ||
                    activeConsult.userApprovedAt ? (
                      <>
                        <div className="col-7">
                          <PreviewButton
                            loading={savingForm}
                            onTap={this.reopenCaseForQC}
                            className="mt-3 mr-2"
                          >
                            <Text fontSize="16px" color="white">
                              Reopen Case For QC{' '}
                            </Text>{' '}
                          </PreviewButton>{' '}
                        </div>{' '}
                        {insurer === 'aegon' || insurer === 'ppmc' ? (
                          <>
                            <div className="col-7">
                              <PreviewButton
                                loading={savingForm}
                                onTap={this.repeatCase}
                                className="mt-3 mr-2"
                              >
                                <Text fontSize="16px" color="white">
                                  Repeat VMER{' '}
                                </Text>{' '}
                              </PreviewButton>{' '}
                            </div>
                          </>
                        ) : (
                          ''
                        )}{' '}
                        {['reliance', 'aegon', 'ipru', 'tata'].includes(
                          insurer,
                        ) ? (
                          <div className="col-7">
                            <PreviewButton
                              loading={savingForm}
                              onTap={this.resendCallback}
                              className="mt-3 mr-2"
                            >
                              <Text fontSize="16px" color="white">
                                Resend Via API
                              </Text>{' '}
                            </PreviewButton>{' '}
                          </div>
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      ''
                    )}{' '}
                    {/* <div>
                                            {this.state.uploadSuccess ? (
                                              <Cell as="span">Upload success</Cell>
                                            ) : insurer === 'ipru' ||
                                              insurer === 'lic' ||
                                              insurer === 'aegon' ? (
                                              <ActionContainer
                                                onTap={() => this.uploadScreenshot('photoid')}
                                              >
                                                <input
                                                  type="file"
                                                  style={{ display: 'none' }}
                                                  accept=".jpg,.png,.jpeg"
                                                  id={`screenshotFile-photoid-${activeConsult.consultationId}`}
                                                  onChange={(ev) =>
                                                    this.handleScreenshotUpload(ev, 'photoid')
                                                  }
                                                />
                                                <UploadButton />
                                                <Cell as="span">Upload screenshot</Cell>
                                                {this.state.uploadingScreenshot && (
                                                  <UploadingComponent
                                                    uploadProgress={`${this.state.uploadProgress}%`}
                                                    borderRadius={0}
                                                  />
                                                )}
                                              </ActionContainer>
                                            ) : (
                                              ''
                                            )}
                                            {this.state.uploadSuccess2 ? (
                                              <Cell as="span">Upload full screenshot success</Cell>
                                            ) : insurer === 'aegon' ? (
                                              <ActionContainer
                                                onTap={() => this.uploadScreenshot('full')}
                                              >
                                                <input
                                                  type="file"
                                                  style={{ display: 'none' }}
                                                  accept=".jpg,.png,.jpeg"
                                                  id={`screenshotFile-full-${activeConsult.consultationId}`}
                                                  onChange={(ev) =>
                                                    this.handleScreenshotUpload(ev, 'full')
                                                  }
                                                />
                                                <UploadButton />
                                                <Cell as="span">Upload full screenshot</Cell>
                                                {this.state.uploadingScreenshot && (
                                                  <UploadingComponent
                                                    uploadProgress={`${this.state.uploadProgress}%`}
                                                    borderRadius={0}
                                                  />
                                                )}
                                              </ActionContainer>
                                            ) : (
                                              ''
                                            )}
                                          </div> */}{' '}
                  </div>
                )}{' '}
              {insurer === 'aegon' ? (
                <div className="col-1 row ml-1 mr-1 mt-1">
                  <ActionContainer onTap={() => this.uploadTranscript()}>
                    <input
                      type="file"
                      style={{
                        display: 'none',
                      }}
                      id={`transcriptFile-${activeConsult.user.userId}`}
                      onChange={(ev) => this.handleTranscriptUpload(ev)}
                    />{' '}
                    <UploadButton />
                    <Cell as="span"> Upload Transcript </Cell>{' '}
                    {this.state.uploadingTranscript && (
                      <UploadingComponent
                        uploadProgress={`${this.state.uploadProgress}%`}
                        borderRadius={0}
                      />
                    )}{' '}
                  </ActionContainer>{' '}
                </div>
              ) : (
                ''
              )}
              {['max', 'ipru', 'abhi'].includes(insurer) && admin ? (
                <div className="col-1 row ml-1 mr-1 mt-1">
                  <ActionContainer onTap={() => this.uploadAudio()}>
                    <input
                      type="file"
                      style={{
                        display: 'none',
                      }}
                      id={`audioFile-${activeConsult.user.userId}`}
                      onChange={(ev) => this.handleAudioUpload(ev)}
                    />{' '}
                    <UploadButton />
                    <Cell as="span"> Upload Audio </Cell>{' '}
                    {this.state.uploadingAudio && (
                      <UploadingComponent
                        uploadProgress={`${this.state.uploadProgress}%`}
                        borderRadius={0}
                      />
                    )}{' '}
                  </ActionContainer>{' '}
                </div>
              ) : (
                ''
              )}
              {admin || true ? (
                <div className="ml-4 mt-3 d-flex">
                  {' '}
                  {this.state.uploadSuccess ? (
                    <Cell as="span"> Upload success </Cell>
                  ) : insurer === 'ipru' ||
                    insurer === 'lic' ||
                    insurer === 'aegon' ||
                    insurer === 'abhi' ||
                    insurer === 'mchi' ||
                    insurer === 'absli' ||
                    insurer === 'absli-vv' ||
                    insurer === 'ppmc' ? (
                    <ActionContainer
                      onTap={() => this.uploadScreenshot('photoid')}
                    >
                      <input
                        type="file"
                        style={{
                          display: 'none',
                        }}
                        accept=".jpg,.png,.jpeg"
                        id={`screenshotFile-photoid-${activeConsult.consultationId}`}
                        onChange={(ev) =>
                          this.handleScreenshotUpload(ev, 'photoid')
                        }
                      />{' '}
                      <UploadButton />
                      <Cell as="span"> Upload screenshot </Cell>{' '}
                      {this.state.uploadingScreenshot && (
                        <UploadingComponent
                          uploadProgress={`${this.state.uploadProgress}%`}
                          borderRadius={0}
                        />
                      )}{' '}
                    </ActionContainer>
                  ) : (
                    ''
                  )}{' '}
                  {this.state.uploadSuccess2 ? (
                    <Cell as="span"> Upload full screenshot success </Cell>
                  ) : insurer === 'aegon' ||
                    insurer === 'absli-vv' ||
                    insurer === 'absli' ||
                    insurer === 'ppmc' ? (
                    <ActionContainer
                      onTap={() => this.uploadScreenshot('full')}
                    >
                      <input
                        type="file"
                        style={{
                          display: 'none',
                        }}
                        accept=".jpg,.png,.jpeg"
                        id={`screenshotFile-full-${activeConsult.consultationId}`}
                        onChange={(ev) =>
                          this.handleScreenshotUpload(ev, 'full')
                        }
                      />{' '}
                      <UploadButton />
                      <Cell as="span"> Upload full screenshot </Cell>{' '}
                      {this.state.uploadingScreenshot && (
                        <UploadingComponent
                          uploadProgress={`${this.state.uploadProgress}%`}
                          borderRadius={0}
                        />
                      )}{' '}
                    </ActionContainer>
                  ) : (
                    ''
                  )}{' '}
                  {this.state.uploadSuccess3 ? (
                    <Cell as="span"> Upload paytm screenshot success </Cell>
                  ) : insurer === 'aegon' || insurer === 'ppmc' ? (
                    <ActionContainer
                      onTap={() => this.uploadScreenshot('paytm')}
                    >
                      <input
                        type="file"
                        style={{
                          display: 'none',
                        }}
                        accept=".jpg,.png,.jpeg"
                        id={`screenshotFile-paytm-${activeConsult.consultationId}`}
                        onChange={(ev) =>
                          this.handleScreenshotUpload(ev, 'paytm')
                        }
                      />{' '}
                      <UploadButton />
                      <Cell as="span"> Upload paytm screenshot </Cell>{' '}
                      {this.state.uploadingScreenshot && (
                        <UploadingComponent
                          uploadProgress={`${this.state.uploadProgress}%`}
                          borderRadius={0}
                        />
                      )}{' '}
                    </ActionContainer>
                  ) : (
                    ''
                  )}{' '}
                </div>
              ) : (
                ''
              )}{' '}
              {admin || true ? (
                this.state.uploadVideoSuccess ? (
                  <div className="col-1">
                    <div className="row ml-1 mr-1 mt-1">
                      <Cell as="span"> Video Upload success </Cell>{' '}
                    </div>{' '}
                  </div>
                ) : insurer === 'ipru' ||
                  insurer === 'aegon' ||
                  insurer === 'absli' ||
                  insurer === 'absli-vv' ||
                  insurer === 'abhi' ||
                  insurer === 'mchi' ||
                  insurer === 'lic' ||
                  insurer === 'ppmc' ? (
                  <div className="col-1 row ml-1 mr-1 mt-1">
                    <ActionContainer onTap={() => this.uploadVideo()}>
                      <input
                        type="file"
                        style={{
                          display: 'none',
                        }}
                        accept=".mp4"
                        id={`videoFile-${activeConsult.consultationId}`}
                        onChange={(ev) => this.handleVideoUpload(ev)}
                      />{' '}
                      <UploadButton />
                      <Cell as="span"> Upload Video </Cell>{' '}
                      {this.state.uploadingVideo && (
                        <UploadingComponent
                          uploadProgress={`${this.state.uploadProgress}%`}
                          borderRadius={0}
                        />
                      )}{' '}
                    </ActionContainer>{' '}
                  </div>
                ) : (
                  ''
                )
              ) : (
                ''
              )}{' '}
              {['aegon', 'ipru', 'absli', 'lic', 'absli-vv', 'abhi'].includes(
                insurer,
              ) &&
              activeConsult &&
              !qc &&
              activeConsult.consultationId ? (
                <div className="m-2 p-2">
                  <input
                    className="form-control border "
                    type="text"
                    onChange={(e) => {
                      this.setState({
                        userEmail: e.target.value,
                      });
                    }}
                    value={this.state.userEmail}
                    placeholder="Type User Email or Phone no"
                    id="email-input"
                  />
                  <input
                    className="form-control border "
                    type="text"
                    onChange={(e) => {
                      this.setState({
                        linkToSend: e.target.value,
                      });
                    }}
                    value={this.state.linkToSend}
                    placeholder="Type Link to send"
                    id="link-input"
                  />
                  <PreviewButton
                    onTap={this.sendUserEmailLink}
                    disabled={
                      String(this.state.userEmail).length < 6 ||
                      String(this.state.linkToSend).length < 4 ||
                      this.state.linkSent
                    }
                    className="mt-3 mr-2"
                  >
                    <Text fontSize="16px" color="white">
                      Send Link
                    </Text>{' '}
                  </PreviewButton>{' '}
                  <br />
                </div>
              ) : (
                ''
              )}
              {doctorType === 'mbbs' ? (
                <FormSteps>
                  {['ppmc'].includes(insurer) && activeConsult.closedAt ? (
                    <div className="col-12">
                      <PreviewButton
                        loading={savingForm}
                        onTap={this.resendCallback}
                        className="mt-3 mr-2"
                      >
                        <Text fontSize="16px" color="white">
                          Resend Via API
                        </Text>{' '}
                      </PreviewButton>{' '}
                      <br />
                    </div>
                  ) : (
                    ''
                  )}{' '}
                  {proposerDetails &&
                  insurer === 'max' &&
                  proposerDetails.disclosure &&
                  proposerDetails.productCode &&
                  !proposerDetails.qcApproved &&
                  !proposerDetails.userApproved &&
                  activeConsult.closedAt ? (
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <BoldText fontSize="16px" className="h4 mt-4 mr-4">
                        Disclosure:
                        <select
                          value={
                            this.state.updatedDisclosure ||
                            proposerDetails.disclosure
                          }
                          onChange={(e) => {
                            this.setState({
                              updatedDisclosure: e.target.value,
                            });
                          }}
                        >
                          <option value="YES"> YES </option>{' '}
                          <option value="NO"> NO </option>{' '}
                        </select>{' '}
                      </BoldText>{' '}
                      {this.state.updatedDisclosure &&
                      this.state.updatedDisclosure !== '' &&
                      this.state.updatedDisclosure !==
                        proposerDetails.disclosure ? (
                        <PreviewButton
                          onTap={this.updateDisclosure}
                          loading={this.state.updatingDisclosure}
                        >
                          <Text fontSize="16px" color="white">
                            Update Disclosure{' '}
                          </Text>{' '}
                        </PreviewButton>
                      ) : (
                        ''
                      )}{' '}
                    </div>
                  ) : (
                    ''
                  )}{' '}
                  {proposerDetails &&
                  insurer === 'aegon' &&
                  proposerDetails.disclosure &&
                  !proposerDetails.qcApproved &&
                  !proposerDetails.userApproved &&
                  proposerDetails.closedAt ? (
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <BoldText fontSize="16px" className="h4 mt-4 mr-4">
                        Disclosure:
                        <select
                          value={
                            this.state.updatedDisclosure ||
                            proposerDetails.disclosure
                          }
                          onChange={(e) => {
                            this.setState({
                              updatedDisclosure: e.target.value,
                            });
                          }}
                        >
                          <option value="YES"> YES </option>{' '}
                          <option value="NO"> NO </option>{' '}
                        </select>{' '}
                      </BoldText>{' '}
                      {this.state.updatedDisclosure &&
                      this.state.updatedDisclosure !== '' &&
                      this.state.updatedDisclosure !==
                        proposerDetails.disclosure ? (
                        <PreviewButton
                          onTap={this.updateDisclosure}
                          loading={this.state.updatingDisclosure}
                        >
                          <Text fontSize="16px" color="white">
                            Update Disclosure{' '}
                          </Text>{' '}
                        </PreviewButton>
                      ) : (
                        ''
                      )}{' '}
                    </div>
                  ) : (
                    ''
                  )}{' '}
                  {proposerDetails && proposerDetails.applicationNo ? (
                    <BoldText fontSize="16px" className="h4 mt-4">
                      Application No - {proposerDetails.applicationNo}{' '}
                    </BoldText>
                  ) : (
                    false
                  )}{' '}
                  {activeConsult && activeConsult.userJoiningLink ? (
                    <BoldText fontSize="16px" className="h4 mt-4">
                      User joining link - {activeConsult.userJoiningLink}{' '}
                    </BoldText>
                  ) : (
                    ''
                  )}{' '}
                  {insurer === 'aegon' &&
                  activeConsult &&
                  activeConsult.underWriterLink ? (
                    <BoldText fontSize="16px" className="h4 mt-4">
                      Under Writer Joining Link -{' '}
                      {activeConsult.underWriterLink}{' '}
                    </BoldText>
                  ) : (
                    ''
                  )}{' '}
                  {insurer === 'aegon' &&
                  proposerDetails &&
                  proposerDetails.formLink ? (
                    <BoldText fontSize="16px" className="h4 mt-4">
                      Form Resume Link -{' '}
                      <a target="_blank" href={proposerDetails.formLink}>
                        Open
                      </a>
                    </BoldText>
                  ) : (
                    ''
                  )}{' '}
                  {activeConsult && activeConsult.whatsappDeliveryStatus ? (
                    <div>
                      <BoldText
                        fontSize="16px"
                        className="h4 mt-4"
                        color="#1ebea5"
                      >
                        Whatsapp Delivery Status -{' '}
                        <BoldText
                          fontSize="16px"
                          as="span"
                          className="h4 mt-4"
                          color={returnColorForWhatsappStatus(
                            activeConsult.whatsappDeliveryStatus,
                          )}
                        >
                          {' '}
                          {activeConsult.whatsappDeliveryStatus}{' '}
                        </BoldText>{' '}
                      </BoldText>
                    </div>
                  ) : (
                    ''
                  )}{' '}
                  {proposerDetails && proposerDetails.closedAt ? (
                    <BoldText
                      fontSize="16px"
                      className="h4 mt-4"
                      color="#1ebea5"
                    >
                      Case Completed By Doctor At -{' '}
                      <BoldText fontSize="16px" as="span" className="h4 mt-4">
                        {' '}
                        {moment(proposerDetails.closedAt).format(
                          'DD/MM/YYYY HH:mm',
                        )}{' '}
                      </BoldText>{' '}
                    </BoldText>
                  ) : activeConsult && activeConsult.closedAt ? (
                    <BoldText
                      fontSize="16px"
                      className="h4 mt-4"
                      color="#1ebea5"
                    >
                      Case Completed By Doctor At -{' '}
                      <BoldText fontSize="16px" as="span" className="h4 mt-4">
                        {' '}
                        {moment(activeConsult.closedAt).format(
                          'DD/MM/YYYY HH:mm',
                        )}{' '}
                      </BoldText>{' '}
                    </BoldText>
                  ) : (
                    ''
                  )}{' '}
                  {insurer === 'aegon' && admin && !qc && proposerDetails ? (
                    <div className="ml-4 mt-4">
                      {proposerDetails.value ? (
                        <div
                          id="form-line"
                          style={{
                            display: 'inline-block',
                            justifyContent: 'center',
                          }}
                        >
                          <div>
                            <label className="switch">
                              <input
                                type="checkbox"
                                onChange={this.jointMerToggle}
                                checked={proposerDetails.value ? true : false}
                              />
                              <span className="slider round"> </span>
                            </label>
                          </div>
                          <div>
                            <Text>
                              {' '}
                              Joint MER - {proposerDetails.value ? 'Yes' : 'No'}
                            </Text>
                          </div>
                        </div>
                      ) : (
                        <div
                          id="form-line"
                          style={{
                            display: 'inline-block',
                            justifyContent: 'center',
                          }}
                        >
                          <div>
                            <label className="switch">
                              <input
                                type="checkbox"
                                onChange={this.jointMerToggle}
                                checked={proposerDetails.value ? true : false}
                              />
                              <span className="slider round"> </span>
                            </label>
                          </div>
                          <div>
                            <Text>
                              {' '}
                              Joint MER - {proposerDetails.value ? 'Yes' : 'No'}
                            </Text>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                  {proposerDetails && proposerDetails.proposalNumber ? (
                    <BoldText fontSize="16px" className="h4 mt-4">
                      Proposal No - {proposerDetails.proposalNumber}{' '}
                    </BoldText>
                  ) : (
                    false
                  )}{' '}
                  {proposerDetails && proposerDetails.address ? (
                    <BoldText fontSize="16px" className="h4 mt-4">
                      Address - {proposerDetails.address}{' '}
                    </BoldText>
                  ) : (
                    false
                  )}{' '}
                  {oneConnectLinks && oneConnectLinks.length > 0 && (
                    <div>
                      <BoldText fontSize="16px" className="h4 mt-4">
                        One connect user link{' '}
                      </BoldText>{' '}
                      {oneConnectLinks.map((ele) => {
                        return (
                          <BoldText
                            fontSize="16px"
                            className="h4 mt-4"
                            key={ele.link}
                          >
                            {' '}
                            {ele.memberName}({ele.scheduleTime}) - {ele.link}{' '}
                          </BoldText>
                        );
                      })}{' '}
                    </div>
                  )}{' '}
                  <BoldText fontSize="16px" className="h4 mt-4">
                    Proposer Details{' '}
                  </BoldText>{' '}
                  {proposerDetails ? (
                    <div className="table-responsive mt-3">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">
                              <BoldText fontSize="14px"> Name </BoldText>{' '}
                            </th>{' '}
                            <th scope="col">
                              <BoldText fontSize="14px">Gender</BoldText>
                            </th>
                            <th scope="col">
                              <BoldText fontSize="14px">DoB</BoldText>
                            </th>
                            {insurer === 'mchi' ? (
                              <th scope="col">
                                <BoldText fontSize="14px">Email </BoldText>{' '}
                              </th>
                            ) : (
                              ''
                            )}{' '}
                            {admin ? (
                              <>
                                <th scope="col">
                                  <BoldText fontSize="14px">
                                    {' '}
                                    Mobile No{' '}
                                  </BoldText>{' '}
                                </th>{' '}
                                {insurer === 'aegon' ? (
                                  <th scope="col">
                                    <BoldText fontSize="14px">
                                      Email No{' '}
                                    </BoldText>{' '}
                                  </th>
                                ) : (
                                  ''
                                )}{' '}
                                {['lic', 'abhi'].includes(insurer) ? (
                                  <th scope="col">
                                    <BoldText fontSize="14px">
                                      Update Info{' '}
                                    </BoldText>{' '}
                                  </th>
                                ) : (
                                  ''
                                )}{' '}
                              </>
                            ) : (
                              <th scope="col">
                                <BoldText fontSize="14px"> Mobile No </BoldText>{' '}
                              </th>
                            )}{' '}
                          </tr>{' '}
                        </thead>{' '}
                        <tbody>
                          <tr>
                            <td>
                              <Text fontSize="14px">
                                {' '}
                                {proposerDetails.name}{' '}
                              </Text>{' '}
                            </td>{' '}
                            <td>
                              <Text fontSize="14px">
                                {proposerDetails.gender}
                              </Text>
                            </td>
                            <td>
                              <Text fontSize="14px">{proposerDetails.dob}</Text>
                            </td>
                            {insurer === 'mchi' ? (
                              <td>
                                <Text fontSize="14px">
                                  {' '}
                                  {proposerDetails.email}{' '}
                                </Text>{' '}
                              </td>
                            ) : (
                              ''
                            )}{' '}
                            {admin ? (
                              <>
                                <td>
                                  <Text fontSize="14px">
                                    {['absli-vv', 'max'].includes(insurer)
                                      ? proposerDetails.phone
                                      : `${String(
                                          Number(proposerDetails.phone),
                                        ).substr(0, 4)}
                                    XXXXXX`}
                                  </Text>{' '}
                                </td>{' '}
                                {insurer === 'aegon' ? (
                                  <td>
                                    <Text fontSize="14px">
                                      {' '}
                                      {proposerDetails.email}{' '}
                                    </Text>{' '}
                                  </td>
                                ) : (
                                  ''
                                )}{' '}
                                {['lic', 'abhi'].includes(insurer) && (
                                  <td>
                                    <PreviewButton
                                      loading={savingForm}
                                      onTap={() => {
                                        if (insurer === 'abhi') {
                                          this.updateRelative({
                                            ...proposerDetails,
                                            isProposerDetails: true,
                                          });
                                        } else {
                                          this.updateRelativeLic(
                                            proposerDetails,
                                          );
                                        }
                                      }}
                                    >
                                      <Text fontSize="16px" color="white">
                                        Update Info{' '}
                                      </Text>{' '}
                                    </PreviewButton>{' '}
                                  </td>
                                )}{' '}
                              </>
                            ) : (
                              <td>
                                <Text fontSize="14px">
                                  {proposerDetails.phone && false
                                    ? `${String(
                                        Number(proposerDetails.phone),
                                      ).substr(0, 4)}XXXXXX`
                                    : ''}
                                </Text>{' '}
                              </td>
                            )}{' '}
                          </tr>{' '}
                        </tbody>{' '}
                      </table>{' '}
                    </div>
                  ) : (
                    <BoldText fontSize="14px" className="text-danger">
                      No proposer details were found{' '}
                    </BoldText>
                  )}{' '}
                  <>
                    <BoldText fontSize="16px" className="h4 mt-4">
                      Member Details{' '}
                    </BoldText>{' '}
                    <div className="table-responsive mt-3">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            {' '}
                            {relatives &&
                              relatives[0] &&
                              relatives[0].memberId && (
                                <th scope="col">
                                  <BoldText fontSize="14px">
                                    {' '}
                                    Member ID{' '}
                                  </BoldText>{' '}
                                </th>
                              )}{' '}
                            <th scope="col">
                              <BoldText fontSize="14px"> Name </BoldText>{' '}
                            </th>{' '}
                            <th scope="col">
                              <BoldText fontSize="14px"> Gender </BoldText>{' '}
                            </th>{' '}
                            <th scope="col">
                              <BoldText fontSize="14px"> DoB </BoldText>{' '}
                            </th>{' '}
                            <th scope="col">
                              <BoldText fontSize="14px"> Relation </BoldText>{' '}
                            </th>{' '}
                            <th scope="col">
                              <BoldText fontSize="14px">
                                Submitted Transcript{' '}
                              </BoldText>{' '}
                            </th>{' '}
                            <th scope="col">
                              <BoldText fontSize="14px"> Screenshot </BoldText>{' '}
                            </th>{' '}
                            {insurer === 'abhi' && admin ? (
                              <th scope="col">
                                <BoldText fontSize="14px"> Phone No </BoldText>{' '}
                              </th>
                            ) : (
                              ''
                            )}{' '}
                            {(insurer === 'abhi' || insurer === 'mchi') &&
                              admin && (
                                <th scope="col">
                                  <BoldText fontSize="14px">
                                    {' '}
                                    Update Info{' '}
                                  </BoldText>{' '}
                                </th>
                              )}{' '}
                          </tr>{' '}
                        </thead>{' '}
                        <tbody>
                          {' '}
                          {relatives &&
                            relatives.map((ele) => (
                              <tr key={`${ele.memberId}_${ele.name}`}>
                                {' '}
                                {ele.memberId && (
                                  <td>
                                    <Text fontSize="14px">
                                      {' '}
                                      {ele.memberId}{' '}
                                    </Text>{' '}
                                  </td>
                                )}{' '}
                                <td>
                                  <Text fontSize="14px"> {ele.name} </Text>{' '}
                                </td>{' '}
                                <td>
                                  <Text fontSize="14px"> {ele.gender} </Text>{' '}
                                </td>{' '}
                                <td>
                                  <Text fontSize="14px">
                                    {' '}
                                    {insurer === 'abhi'
                                      ? ele.dobTxt
                                      : ele.dob}{' '}
                                  </Text>{' '}
                                </td>{' '}
                                <td>
                                  <Text fontSize="14px"> {ele.relation} </Text>{' '}
                                </td>{' '}
                                <td>
                                  <Text fontSize="14px">
                                    {' '}
                                    {ele.transcriptUrl && (admin || qc) ? (
                                      <a
                                        href={ele.transcriptUrl}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                      >
                                        View{' '}
                                      </a>
                                    ) : (
                                      '-'
                                    )}{' '}
                                  </Text>{' '}
                                </td>{' '}
                                <td>
                                  <Text fontSize="14px">
                                    {' '}
                                    {ele.screenshot ? (
                                      <a
                                        href={ele.screenshot}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                      >
                                        View{' '}
                                      </a>
                                    ) : (
                                      '-'
                                    )}{' '}
                                  </Text>{' '}
                                </td>{' '}
                                {insurer === 'abhi' && admin ? (
                                  <td>
                                    <Text fontSize="14px">
                                      {' '}
                                      {ele.phoneNumber}{' '}
                                    </Text>{' '}
                                  </td>
                                ) : (
                                  ''
                                )}{' '}
                                <td>
                                  {[
                                    'abhi',
                                    'absli',
                                    'ipru',
                                    'absli-vv',
                                    'mchi',
                                    'ppmc',
                                    'tata',
                                  ].includes(insurer) &&
                                    admin && (
                                      <div>
                                        <PreviewButton
                                          loading={savingForm}
                                          onTap={() => this.updateRelative(ele)}
                                        >
                                          <Text fontSize="16px" color="white">
                                            Update Info{' '}
                                          </Text>{' '}
                                        </PreviewButton>
                                        {'abhi' &&
                                        relatives &&
                                        relatives.length > 1 ? (
                                          <AntdButton
                                            loading={savingForm}
                                            onClick={() =>
                                              AntdModal.confirm({
                                                content:
                                                  'Please confirm if you want to split the case',
                                                onOk: () => {
                                                  const body = {
                                                    relativeId: ele.relativeId,
                                                    userId:
                                                      activeConsult.user.userId,
                                                  };
                                                  splitCase(body)
                                                    .then(() => {
                                                      this.props.dispatch(
                                                        resetActiveConsult(),
                                                      );
                                                      this.fetchConsults();
                                                    })
                                                    .catch((err) => {
                                                      console.log(err);
                                                    });
                                                },
                                              })
                                            }
                                            type={'primary'}
                                          >
                                            SPLIT RELATIVE
                                          </AntdButton>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                    )}
                                </td>
                              </tr>
                            ))}{' '}
                        </tbody>{' '}
                      </table>{' '}
                    </div>{' '}
                  </>{' '}
                  <div className="form-group clearfix">
                    <PreviewButton
                      loading={savingForm}
                      onTap={this.previewForm}
                      className="mt-3 mr-2"
                      //disabled={!this.state.alertFeedback}
                    >
                      <Text fontSize="16px" color="white">
                        Preview Form{' '}
                      </Text>{' '}
                    </PreviewButton>{' '}
                  </div>{' '}
                  {formPreviewed && !showPreviewModal && (
                    <div className="form-group mt-4">
                      {' '}
                      {/* <div className="alert alert-primary">
                                                  <BoldText fontSize="14px" color="#242327">
                                                    Please complete the remarks before submitting!
                                                  </BoldText>
                                                </div> */}{' '}
                      {/* <textarea
                                                  className="form-control mt-3"
                                                  placeholder="Enter your remarks here"
                                                  style={{
                                                    fontSize: '1.6rem',
                                                    padding: '6px',
                                                    lineHeight: '1',
                                                    border: '1px solid #242327',
                                                  }}
                                                  value={submissionRemarks}
                                                  onChange={(ev) => {
                                                    this.setState({
                                                      submissionRemarks: ev.target.value,
                                                    });
                                                  }}
                                                ></textarea> */}{' '}
                      {insurer === 'ipru' ? (
                        ''
                      ) : qc ? (
                        <PreviewButton
                          loading={savingForm}
                          disabled={!formPreviewed}
                          onTap={this.markFormApproved}
                          className="mt-3"
                        >
                          <Text fontSize="16px" color="white">
                            Approve Form{' '}
                          </Text>{' '}
                        </PreviewButton>
                      ) : (
                        <PreviewButton
                          loading={savingForm}
                          disabled={!formPreviewed}
                          onTap={this.markFormComplete}
                          className="mt-3"
                        >
                          <Text fontSize="16px" color="white">
                            Submit Form{' '}
                          </Text>{' '}
                        </PreviewButton>
                      )}{' '}
                    </div>
                  )}{' '}
                </FormSteps>
              ) : (
                <>
                  {' '}
                  {userId && (
                    <HraContainer
                      userId={userId}
                      closeConsultation={() =>
                        this.setState({
                          showCloseConsultationModal: true,
                        })
                      }
                    />
                  )}{' '}
                  {this.state.showCloseConsultationModal && (
                    <CloseConsultationModal
                      closeConsult={(body) => this.closeConsult(body)}
                      hideModal={() => this.hideModal()}
                    />
                  )}{' '}
                </>
              )}{' '}
            </>
          );
      }
    };
    const renderPdf = (ele, idx) => {
      console.log(ele);
      return (
        <div
          className={`mb-1 ${
            this.state.expandedPdf === ele.url ? 'border-primary border' : ''
          }`}
          key={ele.key}
        >
          <div className="card-header p-2" id={'relative' + ele.key}>
            <h4 className="mb-0">
              <button
                className="btn text-left"
                type="button"
                onClick={() => {
                  this.setState({
                    expandedPdf: ele.url,
                  });
                }}
              >
                <BoldText
                  fontSize={
                    this.state.expandedPdf === ele.url ? '16px' : '14px'
                  }
                  color={'var(--blue)'}
                >
                  {ele.header}
                </BoldText>
              </button>
            </h4>
          </div>
        </div>
      );
    };
    const renderTabs = () => {
      return (
        <TabContainer>
          <TabItemContainer
            active={
              activeTab === 'profile' || activeTab === '' || activeTab === null
            }
            onTap={() => {
              this.setState({
                activeTab: 'profile',
              });
            }}
          >
            {' '}
            {activeTab === 'profile' ||
            activeTab === '' ||
            activeTab === null ? (
              <TabActiveItemText> Profile </TabActiveItemText>
            ) : (
              <TabItemText> Profile </TabItemText>
            )}{' '}
          </TabItemContainer>{' '}
          {doctorType === 'mbbs' && (
            <>
              <TabItemContainer
                active={activeTab === 'script'}
                onTap={() => {
                  this.setState({
                    activeTab: 'script',
                  });
                }}
              >
                {' '}
                {activeTab === 'script' ? (
                  <TabActiveItemText> Script </TabActiveItemText>
                ) : (
                  <TabItemText> Script </TabItemText>
                )}{' '}
              </TabItemContainer>{' '}
              <TabItemContainer
                active={activeTab === 'callLogs'}
                onTap={() => {
                  this.setState({
                    activeTab: 'callLogs',
                  });
                }}
              >
                {' '}
                {activeTab === 'callLogs' ? (
                  <TabActiveItemText> Call Logs </TabActiveItemText>
                ) : (
                  <TabItemText> Call Logs </TabItemText>
                )}{' '}
              </TabItemContainer>{' '}
            </>
          )}{' '}
        </TabContainer>
      );
    };
    return (
      <OuterContainer>
        {' '}
        {!this.props.only && renderTabs()}{' '}
        <BodyContainer> {renderActiveTab()} </BodyContainer>{' '}
        {this.state.updateInfoModal && (
          <UpdateInfoModal
            relative={this.state.selectedRelative}
            hideModal={() => this.hideModal()}
            userId={activeConsult.user.userId}
            fetchConsults={this.fetchConsults}
          />
        )}{' '}
        {this.state.updateInfoModalLic && (
          <UpdateInfoModalLic
            relative={this.state.selectedRelative}
            hideModal={() => this.hideModal()}
            userId={activeConsult.user.userId}
          />
        )}{' '}
        {showPreviewModal ? (
          <Modal>
            <ModalContainer>
              <ModalBody
                className="overflow-auto"
                style={
                  insurer === 'reliance' && qc
                    ? {
                        height: '90%',
                        maxHeight: '90%',
                      }
                    : {}
                }
              >
                <CloseContainer
                  onTap={() =>
                    this.setState({
                      showPreviewModal: false,
                      reportViewed: false,
                    })
                  }
                >
                  <CloseIcon />
                </CloseContainer>{' '}
                <BoldText fontSize="22px" color="#242327">
                  Please review the form to submit.{' '}
                </BoldText>{' '}
                {this.state.additionalReportUrl &&
                this.state.additionalReportUrl.length > 0 ? (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                      }}
                    >
                      {this.state.additionalReportUrl.map(renderPdf)}
                    </div>
                    {this.state.expandedPdf ? (
                      <iframe
                        id="pdfview"
                        onLoad={this.onIframeLoad}
                        title="MER"
                        src={`/pdfjs-2.5.207-dist/web/viewer.html?file=${this.state.expandedPdf}`}
                      ></iframe>
                    ) : (
                      ''
                    )}
                  </>
                ) : insurer === 'reliance' && qc && false ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    {previewReport ? (
                      <div style={{ width: '50%' }}>
                        <strong>CURRENT TRANSCRIPT</strong>
                        <iframe
                          id="pdfview"
                          style={{
                            width: '100%',
                            height: '80%',
                          }}
                          onLoad={this.onIframeLoad}
                          title="MER"
                          src={`/pdfjs-2.5.207-dist/web/viewer.html?file=${previewReport}`}
                        ></iframe>
                      </div>
                    ) : (
                      ''
                    )}

                    {proposerDetails.pdfUrl != null ? (
                      <div style={{ width: '50%' }}>
                        <strong>MER DOCTOR TRANSCRIPT</strong>
                        <iframe
                          id="pdfviewPrev"
                          style={{
                            width: '100%',
                            height: '80%',
                            marginTop: '20px',
                          }}
                          onLoad={this.onIframeLoad}
                          title="MER"
                          src={`/pdfjs-2.5.207-dist/web/viewer.html?file=${proposerDetails.pdfUrl}`}
                        ></iframe>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  <iframe
                    id="pdfview"
                    onLoad={this.onIframeLoad}
                    title="MER"
                    src={`/pdfjs-2.5.207-dist/web/viewer.html?file=${previewReport}`}
                  ></iframe>
                )}
                {qc ? (
                  <>
                    <div style={{ display: 'inline-block' }}>
                      <div
                        style={{
                          display: 'flex',
                          gap: '4rem',
                          marginBottom: '2rem',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            gap: '1rem',
                            marginTop: '2rem',
                          }}
                        >
                          <Text fontSize="16px"> Feedback Remarks:</Text>

                          <div style={{ width: '200px' }}>
                            <Select
                              options={FeedbackSelectOptions}
                              // isDisabled={qc}
                              onChange={this.handleChange}
                              value={FeedbackSelectOptions.filter(
                                (option) => option.value === this.state.optVal,
                              )}
                              classNamePrefix="react-select"
                            />{' '}
                          </div>
                        </div>

                        {insurer === 'max' ? (
                          <div
                            style={{
                              display: 'flex',
                              gap: '1rem',
                              marginTop: '2rem',
                            }}
                          >
                            <Text fontSize="16px">NPS Rating:</Text>
                            <div style={{ width: '200px' }}>
                              <Select
                                options={NPSRatingOptions}
                                // isDisabled={qc}
                                onChange={(e) => {
                                  this.setState({
                                    npsVal: e.value,
                                  });
                                }}
                                value={NPSRatingOptions.filter(
                                  (option) =>
                                    option.value === this.state.npsVal,
                                )}
                                classNamePrefix="react-select"
                              />{' '}
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>

                      <div>
                        <textarea
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="4"
                          width="100%"
                          onChange={(e) => {
                            this.setState({
                              feedback: e.target.value,
                            });
                          }}
                          value={this.state.feedback}
                          placeholder="Enter Feedback in this section ..."
                        ></textarea>
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )}
                {insurer === 'ipru' ? (
                  ''
                ) : qc ? (
                  <PreviewButton
                    loading={savingForm}
                    disabled={!reportViewed || savingForm}
                    onTap={this.markFormApproved}
                    className="mt-3"
                  >
                    <Text fontSize="16px" color="white">
                      Approve Form{' '}
                    </Text>{' '}
                  </PreviewButton>
                ) : (
                  <PreviewButton
                    loading={savingForm}
                    disabled={!reportViewed || savingForm}
                    onTap={this.markFormComplete}
                    className="mt-3"
                  >
                    <Text fontSize="16px" color="white">
                      Submit Form{' '}
                    </Text>{' '}
                  </PreviewButton>
                )}{' '}
              </ModalBody>{' '}
            </ModalContainer>{' '}
          </Modal>
        ) : (
          false
        )}{' '}
        {showFollowupModal && (
          <Modal>
            <ModalContainer>
              <FollowupModalBody>
                <ModalHeader>
                  <HeaderText> Schedule Followup </HeaderText>{' '}
                  <ButtonsComponent>
                    <CloseContainer onTap={this.hideModal}>
                      <CrossIcon />
                    </CloseContainer>{' '}
                  </ButtonsComponent>{' '}
                </ModalHeader>{' '}
                <Body>
                  <LabelText>
                    {' '}
                    {`Set a time to followup with ${activeConsult.user.userName}`}{' '}
                  </LabelText>{' '}
                  <DatePicker
                    selected={followupDate}
                    minDate={now}
                    minTime={
                      new Date().getDate() ===
                      new Date(this.state.followupDate || new Date()).getDate()
                        ? new Date().setMinutes(new Date().getMinutes() + 6)
                        : new Date().setHours(6, 0, 0)
                    }
                    maxTime={new Date().setHours(21, 59, 59)}
                    onChange={this.onFollowupDateChanged}
                    showTimeSelect
                    timeFormat="hh:mm a"
                    timeIntervals={10}
                    dateFormat="yyyy-MM-dd HH:mm"
                  />
                  <ButtonComponent>
                    <Button
                      onTap={this.setFollowup}
                      loading={this.state.voiceCallLoading}
                    >
                      <ButtonText> Confirm </ButtonText>{' '}
                    </Button>{' '}
                  </ButtonComponent>{' '}
                </Body>{' '}
              </FollowupModalBody>{' '}
            </ModalContainer>{' '}
          </Modal>
        )}{' '}
        {this.state.showSelectVideoModal && (
          <SelectVideoModal
            hideModal={() => this.hideModal()}
            sessionId={this.state.sessionId}
          />
        )}
        {this.state.showSendInReportModal ? (
          <SendInDailyReportModal
            userId={activeConsult.user.userId}
            hideModal={() => {
              this.setState({
                showSendInReportModal: false,
              });
            }}
          />
        ) : (
          ''
        )}
        {this.state.selectedRecordingUrl && (
          <DraggablePlayer
            url={this.state.selectedRecordingUrl.url}
            info={this.state.selectedRecordingUrl}
            hideModal={this.hideDraggablePlayer}
            type={activeCallLogTab}
            handleDraggablePlayerControl={this.handleDraggablePlayerControl}
          />
        )}
        {this.state.showPlayTimeRemarksModal && (
          <PlaytimeRemarksModal
            submitPlaytimeRemarks={this.submitPlaytimeRemarks}
            hideModal={() => this.setState({ showPlayTimeRemarksModal: false })}
          />
        )}
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  userId: state.chat.receiverId,
  admin: state.user.admin,
  active: state.user.active,
  requestType: state.consults.activeConsult.requestType,
  consultationId: state.chat.consultationId,
  activeConsult: state.consults.activeConsult,
  doctorType: state.user.doctorType,
  activeFilterName: state.info.activeFilterName,
  callRemarks: state.info.callRemarks,
  relatives: state.consults.activeConsult.relatives,
  callMade: state.info.callMade,
  videoCallMade: state.info.videoCallMade,
  proposerDetails: state.consults.activeConsult.proposerDetails,
  oneConnectLinks: state.consults.activeConsult.oneConnectLinks,
  insurer: state.user.insurer,
  qc: state.user.qc,
  armsoft: state.user.armsoft,
  vendorId: state.user.vendorId,
  doctorId: state.user.doctorId,
});

export default withRouter(connect(mapStateToProps)(ProfileContainer));
