import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  BlackText,
  BoldText,
  RegularText,
  Text,
} from '../components/common/Text';
import StyledTappable from '../components/common/StyledTappable';
import { showSuccess, updateInsurer } from '../actions';
import { changeSelfInsurer, getNewQcCases, getQcCaseCount } from '../services';
import { BiRefresh, BiUserPlus } from 'react-icons/bi';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`;

const TabContainer = styled.div`
  display: flex;
  background: #f5f5f5;
  align-items: stretch;
  justify-content: space-between;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.23);
`;

const TabItemContainer = styled(StyledTappable)`
  padding: 10px;
  border-bottom: ${(props) => (props.active ? '3px solid #8852CC' : 'none')};
`;

const TabActiveItemText = styled(BoldText)`
  font-size: 1.6rem;
  color: #8852cc;
`;

const TabItemText = styled(Text)`
  font-size: 1.6rem;
  opacity: 0.4;
`;

const CountTag = styled.span`
  color: white;
  font-weight: 600;
  padding: 2px 7px;
  background: red;
  border-radius: 50%;
  margin-left: 4px;
`;

class ChangeInsurer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 'doctorStats',
      loading: false,
      insurers: [
        'max',
        'ipru',
        'aegon',
        'abhi',
        'reliance',
        'absli',
        'lic',
        'absli-vv',
        'mchi',
        'ppmc',
        'tata',
      ],
      maxCount: 0,
      ipruCount: 0,
      aegonCount: 0,
      abhiCount: 0,
      relianceCount: 0,
      absliCount: 0,
      absliVvCount: 0,
      licCount: 0,
      mchiCount: 0,
      ppmcCount: 0,
      refreshing: false,
      ppmcCount: 0,
      godigitCount: 0,
      tataCount: 0,
    };
    this.state.currentTab = props.insurer;
  }

  componentDidMount() {
    // if (!this.props.qc) {
    //   this.setState({ insurers: [...this.state.insurers, 'lic'] });
    // }
    if (this.props.qc) {
      this.setState({ insurers: [...this.state.insurers] });
      this.getQcCaseCount();
    }
  }

  updateInsurer = (insurer) => {
    changeSelfInsurer(this.props.doctorId, insurer)
      .then((res) => {
        this.props.dispatch(updateInsurer(insurer, res.headers.authorization));
        this.setState(
          {
            currentTab: insurer,
          },
          () =>
            setTimeout(() => {
              window.location.reload(true);
            }, 1000),
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };

  getQcCaseCount = () => {
    this.setState({
      refreshing: true,
    });
    getQcCaseCount()
      .then((res) => {
        if (res.result) {
          this.setState({
            maxCount: res.result.maxCount || 0,
            ipruCount: res.result.ipruCount || 0,
            aegonCount: res.result.aegonCount || 0,
            abhiCount: res.result.abhiCount || 0,
            relianceCount: res.result.relianceCount || 0,
            absliCount: res.result.absliCount || 0,
            absliVvCount: res.result.absliVvCount || 0,
            licCount: res.result.licCount || 0,
            mchiCount: res.result.mchiCount || 0,
            ppmcCount: res.result.ppmcCount || 0,
            godigitCount: res.result.godigitCount || 0,
            tataCount: res.result.tataCount || 0,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          refreshing: false,
        });
      });
  };

  getCount = (insurer) => {
    switch (insurer) {
      case 'max':
        return this.state.maxCount;
      case 'ipru':
        return this.state.ipruCount;
      case 'aegon':
        return this.state.aegonCount;
      case 'abhi':
        return this.state.abhiCount;
      case 'mchi':
        return this.state.mchiCount;
      case 'reliance':
        return this.state.relianceCount;
      case 'absli':
        return this.state.absliCount;
      case 'absli-vv':
        return this.state.absliVvCount;
      case 'lic':
        return this.state.licCount;
      case 'ppmc':
        return this.state.ppmcCount;
      case 'godigit':
        return this.state.godigitCount;
      case 'tata':
        return this.state.tataCount;
      default:
        return 0;
    }
  };

  getNewCases = () => {
    this.setState({
      refreshing: true,
    });
    getNewQcCases()
      .then((res) => {
        console.log(res);
        this.props.dispatch(showSuccess('Cases Assigned', res.displayMessage));
        this.getQcCaseCount();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          refreshing: false,
        });
      });
  };

  render() {
    const { currentTab, insurers } = this.state;
    const { qc } = this.props;
    return (
      <OuterContainer>
        <TabContainer>
          {insurers.map((ele) => (
            <TabItemContainer
              active={currentTab === ele}
              onTap={() => {
                this.updateInsurer(ele);
              }}
            >
              {currentTab === ele ? (
                <TabActiveItemText>
                  {ele === 'aegon' ? 'bandhan-life' : ele}
                  {qc && this.getCount(ele) > 0 ? (
                    <CountTag>{this.getCount(ele)}</CountTag>
                  ) : (
                    ''
                  )}
                </TabActiveItemText>
              ) : (
                <TabItemText>
                  {ele === 'aegon' ? 'bandhan-life' : ele}
                  {qc && this.getCount(ele) > 0 ? (
                    <CountTag>{this.getCount(ele)}</CountTag>
                  ) : (
                    ''
                  )}
                </TabItemText>
              )}
            </TabItemContainer>
          ))}
          {qc ? (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <TabItemContainer
                active={false}
                onTap={() => {
                  if (!this.state.refreshing) {
                    this.getNewCases();
                  }
                }}
              >
                <BiUserPlus
                  style={{
                    fontSize: '24px',
                  }}
                  className={this.state.refreshing ? 'icon-spin' : ''}
                />
              </TabItemContainer>
              <TabItemContainer
                active={false}
                onTap={() => {
                  if (!this.state.refreshing) {
                    this.getQcCaseCount();
                  }
                }}
              >
                <BiRefresh
                  style={{
                    fontSize: '24px',
                  }}
                  className={this.state.refreshing ? 'icon-spin' : ''}
                />
              </TabItemContainer>
            </div>
          ) : (
            ''
          )}
        </TabContainer>
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  doctorsList: state.info.doctorsList,
  authToken: state.user.authToken,
  insurer: state.user.insurer,
  doctorId: state.user.doctorId,
  qc: state.user.qc,
  changeInsurer: state.user.changeInsurer,
});

export default connect(mapStateToProps)(ChangeInsurer);
