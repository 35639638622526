import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Select from 'react-select';
import ReactTable from 'react-table';
import selectTableHoc from 'react-table/lib/hoc/selectTable';
import { FaFileUpload, FaPowerOff, FaSignature, FaTimes } from 'react-icons/fa';
import { Tag, Switch } from 'antd';

import {
  BlackText,
  BoldText,
  RegularText,
  Text,
} from '../components/common/Text';
import StyledTappable from '../components/common/StyledTappable';
import Modal from '../containers/common/Modal';
import {
  adminConsole,
  goActive,
  goInactive,
  uploadSignature,
  loginInfo,
  getCasesForRemark,
  transferCases,
  fetchConsults,
  fetchConsultsOnPagination,
  autoDailer,
  getDivisionList,
  getQcPendingCaseDays,
  transferToVendor,
  updateInstantTele,
} from '../services';
import { initializeChatClient } from '../services/chatClient';
import UploadingComponent from '../components/common/UploadingComponent';
import { setDoctorsList } from '../actions';
import { PrimaryButton } from '../components/common/Buttons';
import moment from 'moment';

const SelectTable = selectTableHoc(ReactTable);

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  overflow: hidden;
`;

const TabContainer = styled.div`
  display: flex;
  background: #f5f5f5;
  align-items: stretch;
  justify-content: space-between;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.23);
`;

const HeaderText = styled(BlackText)`
  font-size: 2.4rem;
  margin: 14px 0;
`;

const TabItemContainer = styled(StyledTappable)`
  padding: 10px;
  border-bottom: ${(props) => (props.active ? '3px solid #8852CC' : 'none')};
`;

const TabActiveItemText = styled(BoldText)`
  font-size: 1.6rem;
  color: #8852cc;
`;

const TabItemText = styled(Text)`
  font-size: 1.6rem;
  opacity: 0.4;
`;

const ActionContainer = styled(StyledTappable)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const PowerButton = styled(FaPowerOff)`
  margin-right: 8px;
`;

const UploadButton = styled(FaFileUpload)`
  margin-right: 8px;
`;

const Cell = styled(RegularText)`
  color: 'rgba(0,0,0,0.7)';
  margin-right: 10px;
`;

const SignatureIcon = styled(FaSignature)`
  margin-right: 8px;
`;
const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 70%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 1.825rem 1.25rem 1.875rem 1.25rem;
`;

const CloseContainer = styled(StyledTappable)`
  /* align-self: flex-end; */
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;

const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1.625rem;
  align-items: center;
  justify-content: space-between;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`;

const DateText = styled(Text)`
  font-size: 1.2rem;
  opacity: 0.3;
`;

const TimeText = styled(Text)`
  padding-top: 0.25rem;
  font-size: 1.2rem;
  color: #d72323;
`;

class AdminPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      doctorsList: [],
      selectedRemark: null,
      callRemarksOptions: [],
      remarksCases: [],
      remarksCasesLoading: false,
      remarkCasesSelected: new Set(),
      transferModalShown: false,
      selectedTransferDoctorId: -1,
      currentTab: 'doctorStats',
      loading: false,
      transferLoading: false,
      uploadingSignature: false,
      uploadSignatureDoctorId: -1,
      uploadProgress: 0,
      remarksPage: 0,
      remarksPages: 2,
      autoDailing: false,
      qcDoctors: [],
      divisionList: [],
      qcPendingDays: [],
      allCases: [],
      filterOptions: [
        {
          value: 'unassigned',
          label: 'Unassigned',
          openFilter: true,
        },
        {
          value: 'unattempted',
          label: 'Unattempted',
          openFilter: true,
        },
        {
          value: 'same-day-case',
          label: 'Same Day pending cases',
          openFilter: true,
          specificInsurer: true,
          insurers: [
            'max',
            'ipru',
            'abhi',
            'mchi',
            'ppmc',
            'reliance',
            'lic',
            'absli',
            'absli-vv',
            'aegon',
            'godigit',
            'tata',
          ],
        },
        {
          value: 'prev-day-case',
          label: 'Previous Day pending cases',
          openFilter: true,
          specificInsurer: true,
          insurers: [
            'max',
            'ipru',
            'abhi',
            'mchi',
            'ppmc',
            'reliance',
            'lic',
            'absli',
            'absli-vv',
            'aegon',
            'godigit',
            'tata',
          ],
        },
        {
          value: 'long-pending-case',
          label: 'Long pending cases',
          openFilter: true,
        },
        {
          value: 'unattemptedVideo',
          label: 'Unattempted video cases',
          openFilter: true,
          specificInsurer: true,
          insurers: [
            'aegon',
            'lic',
            'abhi',
            'ipru',
            'absli',
            'absli-vv',
            'mchi',
            'ppmc',
          ],
        },
        {
          value: 'unassignedVideo',
          label: 'Unassigned video cases',
          openFilter: true,
          specificInsurer: true,
          insurers: [
            'aegon',
            'ppmc',
            'lic',
            'abhi',
            'ipru',
            'absli',
            'absli-vv',
            'mchi',
          ],
        },
        {
          value: 'unassignedQc',
          label: 'Unassigned QC Case',
          openFilter: true,
        },
        {
          value: 'pivc',
          label: 'PIVC CASE',
          openFilter: true,
          specificInsurer: true,
          insurers: ['aegon', 'ppmc'],
        },
        {
          value: 'pivv',
          label: 'PIVV CASE',
          openFilter: true,
          specificInsurer: true,
          insurers: ['aegon', 'ppmc'],
        },
        {
          value: 'High Priority Case',
          label: 'Pending Case with recording',
          openFilter: true,
          specificInsurer: true,
          insurers: ['max'],
        },
        {
          value: 'Banca Case',
          label: 'Banca Case(MAX)',
          openFilter: true,
          specificInsurer: true,
          insurers: ['max', 'reliance'],
        },
        {
          value: 'Duplicate Case',
          label: 'Duplicate Case(MAX)',
          openFilter: true,
          specificInsurer: true,
          insurers: ['max'],
        },
        {
          value: 'Banca Case',
          label: 'Armsoft Outbound',
          openFilter: true,
          specificInsurer: true,
          insurers: ['ipru'],
        },
        {
          value: 'Armsoft Unassigned',
          label: 'Armsoft Outbound Unassigned',
          openFilter: true,
          specificInsurer: true,
          insurers: ['ipru'],
        },
        {
          value: 'Armsoft UnAttempted',
          label: 'Armsoft Outbound UnAttempted',
          openFilter: true,
          specificInsurer: true,
          insurers: ['ipru'],
        },
        {
          value: 'Paytm Case',
          label: 'Paytm Case(Aegon)',
          openFilter: true,
          specificInsurer: true,
          insurers: ['aegon'],
        },
        {
          value: 'Paytm Case',
          label: 'Paytm Case(Ppmc)',
          openFilter: true,
          specificInsurer: true,
          insurers: ['ppmc'],
        },
        {
          value: 'Paytm Unattempted Case',
          label: 'Paytm Unattempted Case(Aegon)',
          openFilter: true,
          specificInsurer: true,
          insurers: ['aegon'],
        },
        {
          value: 'Paytm Unattempted Case',
          label: 'Paytm Unattempted Case(Ppmc)',
          openFilter: true,
          specificInsurer: true,
          insurers: ['ppmc'],
        },
        {
          value: 'Dailer Answered',
          label: 'Dailer Answered - ALL',
          openFilter: true,
          specificInsurer: true,
          insurers: ['ipru', 'aegon', 'abhi'],
        },
        {
          value: 'Dailer Answered - ONLY VIDEO',
          label: 'Dailer Answered - ONLY VIDEO',
          openFilter: true,
          specificInsurer: true,
          insurers: ['ipru'],
        },
        {
          value: 'Dailer Unanswered',
          label: 'Dailer Unanswered',
          openFilter: true,
          specificInsurer: true,
          insurers: ['ipru', 'abhi'],
        },
        {
          value: 'discrepency-case',
          label: 'Discrepency CASE',
          openFilter: true,
          specificInsurer: true,
          insurers: ['ipru'],
        },
        {
          value: 'discrepency-case',
          label: 'Joint MER',
          openFilter: true,
          specificInsurer: true,
          insurers: ['aegon'],
        },
        {
          value: 'zooper',
          label: 'ZOOPER CASES',
          openFilter: true,
          specificInsurer: true,
          insurers: ['aegon'],
        },
      ],
    };

    this.chatClient = initializeChatClient();
    this.chatClient.addMessageListener((payload) => {
      if (payload.type === 'ops') {
        this.fetchDoctorsList();
      }
      if (payload.type === 'admin-ops') {
        this.fetchDoctorsList();
      }
    });
  }

  componentDidMount() {
    this.setFilterOptions();
    this.getCallRemarksOptions();
    if (this.props.insurer === 'lic') {
      this.getDivisionList();
    }
    if (this.props.qc) {
      this.getQcPendingCaseDays();
    }
  }

  setFilterOptions = () => {
    const { insurer } = this.props;
    let options = [...this.state.filterOptions];
    options = options.filter(
      (ele) =>
        !ele.specificInsurer ||
        (ele.insurers && ele.insurers.includes(insurer)),
    );
    this.setState({
      filterOptions: options,
    });
  };

  getDivisionList = () => {
    getDivisionList()
      .then((res) => {
        this.setState({
          divisionList:
            res.result.map((ele) => {
              return {
                value: ele.value,
                label: ele.label,
                openFilter: true,
                isDivision: true,
              };
            }) || [],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getQcPendingCaseDays = () => {
    getQcPendingCaseDays()
      .then((res) => {
        this.setState({
          qcPendingDays:
            res.result.map((ele) => {
              return {
                value: ele.dateDiff,
                label: ele.label,
                openFilter: true,
                qcList: true,
              };
            }) || [],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  fetchDoctorsList = () => {
    const { dispatch, qc } = this.props;
    this.setState({ loading: true });
    adminConsole()
      .then((res) => {
        this.setState({
          doctorsList: qc
            ? [...res.qcDoctors, ...res.activeConsults]
            : res.activeConsults,
          qcDoctors: res.qcDoctors,
        });
        dispatch(
          setDoctorsList(
            qc ? [...res.qcDoctors, ...res.activeConsults] : res.activeConsults,
          ),
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  getCallRemarksOptions() {
    return loginInfo()
      .then((res) => {
        const { callRemarksOptions } = res;
        this.setState({
          callRemarksOptions,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  toggleActive(doctorId, active) {
    this.setState({ loading: true });
    if (!active) {
      goActive(doctorId)
        .then(() => {
          this.fetchDoctorsList();
        })
        .catch((err) => console.log(err))
        .finally(() => this.setState({ loading: false }));
    } else {
      goInactive(doctorId)
        .then(() => {
          this.fetchDoctorsList();
        })
        .catch((err) => console.log(err))
        .finally(() => this.setState({ loading: false }));
    }
  }

  uploadSignature(doctorId) {
    document.querySelector(`#signatureFile-${doctorId}`).click();
  }

  handleSignatureUpload = (doctorId) => (ev) => {
    const files = ev.target.files;
    if (files[0]) {
      const file = files[0];
      const inputTag = document.querySelector(`#signatureFile-${doctorId}`);
      inputTag.value = '';
      this.setState({
        uploadingSignature: true,
        uploadSignatureDoctorId: doctorId,
      });
      const onUploadProgress = (progressEvent) => {
        if (progressEvent.lengthComputable) {
          const { loaded, total } = progressEvent;
          this.setState({
            uploadProgress: (loaded / total) * 100,
          });
        } else {
          this.setState({
            uploadProgress: 100,
          });
        }
      };
      uploadSignature(file, doctorId, onUploadProgress)
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.setState({
            uploadingSignature: false,
            uploadSignatureDoctorId: -1,
            uploadProgress: 0,
          });
        });
    }
  };

  viewSignature = (doctorSignature) => {
    const aTag = document.createElement('a');
    aTag.setAttribute('href', doctorSignature);
    aTag.setAttribute('target', '_blank');
    aTag.click();
  };

  isRemarksCaseSelected = (ele) => {
    const { remarkCasesSelected } = this.state;
    return remarkCasesSelected.has(ele);
  };

  toggleRemarksCase = (ele, blah, obj) => {
    const { remarkCasesSelected } = this.state;
    const updatedRemarkCasesSelected = new Set(remarkCasesSelected);
    if (this.isRemarksCaseSelected(obj.consultationId)) {
      updatedRemarkCasesSelected.delete(obj.consultationId);
      this.setState({
        remarkCasesSelected: updatedRemarkCasesSelected,
      });
    } else {
      updatedRemarkCasesSelected.add(obj.consultationId);
      this.setState({
        remarkCasesSelected: updatedRemarkCasesSelected,
      });
    }
  };

  toggleAllRemarksCases = () => {
    const { remarkCasesSelected, remarksCases } = this.state;
    if (remarkCasesSelected.size === remarksCases.length) {
      this.setState({
        remarkCasesSelected: new Set(),
      });
    } else {
      this.setState({
        remarkCasesSelected: new Set(
          remarksCases.map((ele) => ele.consultationId),
        ),
      });
    }
  };

  selectRemarkToSearch = (ele, action) => {
    if (action.action === 'set-value' || action.action === 'select-option') {
      console.log(ele, action);
      this.setState({
        selectedRemark: ele,
        remarksPage: 0,
        remarksPages: -1,
      });
      if (ele.openFilter) {
        if (ele.value === 'unassigned') {
          this.getOpenCases('unassigned');
        } else if (ele.value === 'unattempted') {
          this.getOpenCases(null, true);
        } else if (ele.value === 'unattemptedVideo') {
          this.getOpenCases(null, true, 'video');
        } else if (ele.value === 'unassignedVideo') {
          this.getOpenCases('unassigned', null, 'video');
        } else if (ele.value === 'unassignedQc') {
          this.getOpenQcCases('unassigned', true);
        } else if (ele.value === 'Policy Bazaar') {
          this.getPolicyBazaarCase();
        } else if (ele.value === 'pivc') {
          this.getPivcCase();
        } else if (ele.value === 'pivv') {
          this.getPivvCase();
        } else if (ele.value === 'bulkCase') {
          this.getBulkCase();
        } else if (ele.value === 'vendorCase') {
          this.getVendorCase();
        } else if (ele.value === 'High Priority Case') {
          this.getHighPriorityCase();
        } else if (ele.value === 'Banca Case') {
          this.getBancaCase(false);
        } else if (ele.value === 'Armsoft Unassigned') {
          this.getBancaCase(true);
        } else if (ele.value === 'Armsoft UnAttempted') {
          this.getBancaCase(false, true);
        } else if (ele.value === 'Paytm Case') {
          this.getBancaCase();
        } else if (ele.value === 'Duplicate Case') {
          this.getDuplicateCase();
        } else if (ele.value === 'Paytm Case') {
          this.getPaytmCase(false);
        } else if (ele.value === 'Paytm Unattempted Case') {
          this.getPaytmCase(true);
        } else if (ele.value === 'Dailer Unanswered') {
          this.getDailerCase(false, false);
        } else if (ele.value === 'Dailer Answered') {
          this.getDailerCase(true, false);
        } else if (ele.value === 'Dailer Answered - ONLY VIDEO') {
          this.getDailerCase(true, true);
        } else if (ele.isDivision === true) {
          this.getLicBranchCase(ele.value);
        } else if (ele.qcList === true) {
          this.getOpenQcPendingCases(true, ele.value);
        } else if (ele.value === 'long-pending-case') {
          this.getLongPendingCases(7);
        } else if (ele.value === 'same-day-case') {
          this.getLongPendingCases(0);
        } else if (ele.value === 'prev-day-case') {
          this.getLongPendingCases('-1');
        } else if (ele.value === 'discrepency-case') {
          this.getDiscrepencyCase();
        } else if (ele.value === 'zooper') {
          this.getZooperCase();
        } else {
          this.getOpenCases(ele.value);
        }
      } else {
        this.getCasesForRemark(ele.value);
      }
    }
  };

  getOpenCases(doctorId, unattempted, callType) {
    const { authToken } = this.props;
    this.setState({
      remarksCasesLoading: true,
    });
    const filters = [
      {
        name: 'Open',
        isSelected: true,
      },
    ];
    if (unattempted) {
      filters.push({
        name: 'Unattempted',
        isSelected: true,
      });
    }
    if (callType === 'video') {
      filters.push({
        name: 'VideoCall cases',
        isSelected: true,
      });
    } else if (!doctorId || doctorId === 'unassigned') {
      filters.push({
        name: 'Voice call cases',
        isSelected: true,
      });
    }

    filters.push({
      name: 'showAllForAdmin',
      isSelected: true,
    });
    fetchConsults(authToken, filters, null, null, null, doctorId)
      .then((res) => {
        const { consultations } = res.responseBody;
        console.log(res.responseURL);
        this.requestUrl = res.responseURL;
        this.setState({
          remarksCases: consultations,
          remarksPages: 2,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          remarksCasesLoading: false,
        });
      });
  }

  getLongPendingCases(day) {
    const { authToken } = this.props;
    this.setState({
      remarksCasesLoading: true,
    });
    const filters = [
      {
        name: 'Open',
        isSelected: true,
      },
    ];

    filters.push({
      name: 'showAllForAdmin',
      isSelected: true,
    });
    if (day !== null) {
      filters.push({
        name: 'pendingSince',
        value: day,
        isSelected: true,
      });
    } else {
      filters.push({
        name: 'pendingSince',
        value: 7,
        isSelected: true,
      });
    }

    fetchConsults(authToken, filters, null, null, null, null)
      .then((res) => {
        const { consultations } = res.responseBody;
        console.log(res.responseURL);
        this.requestUrl = res.responseURL;
        this.setState({
          remarksCases: consultations,
          remarksPages: 2,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          remarksCasesLoading: false,
        });
      });
  }

  getOpenQcCases(doctorId, qcPending = false) {
    const { authToken } = this.props;
    this.setState({
      remarksCasesLoading: true,
    });
    const filters = [];

    if (qcPending) {
      filters.push({
        name: 'qcUnapproved',
        isSelected: true,
      });
    }
    fetchConsults(authToken, filters, null, null, null, doctorId)
      .then((res) => {
        const { consultations } = res.responseBody;
        console.log(res.responseURL);
        this.requestUrl = res.responseURL;
        this.setState({
          remarksCases: consultations,
          remarksPages: 2,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          remarksCasesLoading: false,
        });
      });
  }

  getOpenQcPendingCases(qcPending = false, days) {
    const { authToken } = this.props;
    this.setState({
      remarksCasesLoading: true,
    });
    const filters = [];
    console.log('here s');

    if (qcPending) {
      filters.push({
        name: 'qcUnapproved',
        isSelected: true,
      });
    }
    if (days >= 0) {
      filters.push({
        name: 'qcPendingSince',
        value: days,
        isSelected: true,
      });
    }
    fetchConsults(authToken, filters, null, null, null)
      .then((res) => {
        const { consultations } = res.responseBody;
        console.log(res.responseURL);
        this.requestUrl = res.responseURL;
        this.setState({
          remarksCases: consultations,
          remarksPages: 2,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          remarksCasesLoading: false,
        });
      });
  }

  getPolicyBazaarCase() {
    const { authToken } = this.props;
    this.setState({
      remarksCasesLoading: true,
    });
    const filters = [
      {
        name: 'Open',
        isSelected: true,
      },
    ];
    filters.push({
      name: 'Policy Bazaar',
      isSelected: true,
    });
    filters.push({
      name: 'showAllForAdmin',
      isSelected: true,
    });
    fetchConsults(authToken, filters, null, null, null)
      .then((res) => {
        const { consultations } = res.responseBody;
        console.log(res.responseURL);
        this.requestUrl = res.responseURL;
        this.setState({
          remarksCases: consultations,
          remarksPages: 2,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          remarksCasesLoading: false,
        });
      });
  }
  getPivcCase() {
    const { authToken } = this.props;
    this.setState({
      remarksCasesLoading: true,
    });
    const filters = [
      {
        name: 'Open',
        isSelected: true,
      },
    ];
    filters.push({
      name: 'PIVC cases',
      isSelected: true,
    });
    filters.push({
      name: 'showAllForAdmin',
      isSelected: true,
    });
    fetchConsults(authToken, filters, null, null, null)
      .then((res) => {
        const { consultations } = res.responseBody;
        console.log(res.responseURL);
        this.requestUrl = res.responseURL;
        this.setState({
          remarksCases: consultations,
          remarksPages: 2,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          remarksCasesLoading: false,
        });
      });
  }

  getPivvCase() {
    const { authToken } = this.props;
    this.setState({
      remarksCasesLoading: true,
    });
    const filters = [
      {
        name: 'Open',
        isSelected: true,
      },
    ];
    filters.push({
      name: 'PIVV cases',
      isSelected: true,
    });
    filters.push({
      name: 'showAllForAdmin',
      isSelected: true,
    });
    fetchConsults(authToken, filters, null, null, null)
      .then((res) => {
        const { consultations } = res.responseBody;
        console.log(res.responseURL);
        this.requestUrl = res.responseURL;
        this.setState({
          remarksCases: consultations,
          remarksPages: 2,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          remarksCasesLoading: false,
        });
      });
  }

  getBulkCase() {
    const { authToken } = this.props;
    this.setState({
      remarksCasesLoading: true,
    });
    const filters = [
      {
        name: 'Open',
        isSelected: true,
      },
    ];
    filters.push({
      name: 'bulkCase',
      isSelected: true,
    });
    filters.push({
      name: 'showAllForAdmin',
      isSelected: true,
    });
    fetchConsults(authToken, filters, null, null, null)
      .then((res) => {
        const { consultations } = res.responseBody;
        console.log(res.responseURL);
        this.requestUrl = res.responseURL;
        this.setState({
          remarksCases: consultations,
          remarksPages: 2,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          remarksCasesLoading: false,
        });
      });
  }

  getHighPriorityCase() {
    const { authToken } = this.props;
    this.setState({
      remarksCasesLoading: true,
    });
    const filters = [
      {
        name: 'Open',
        isSelected: true,
      },
    ];
    filters.push({
      name: 'High Priority Case',
      isSelected: true,
    });
    filters.push({
      name: 'showAllForAdmin',
      isSelected: true,
    });
    fetchConsults(authToken, filters, null, null, null)
      .then((res) => {
        const { consultations } = res.responseBody;
        console.log(res.responseURL);
        this.requestUrl = res.responseURL;
        this.setState({
          remarksCases: consultations,
          remarksPages: 2,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          remarksCasesLoading: false,
        });
      });
  }

  getBancaCase(unassigned = false, unattempted = null) {
    const { authToken } = this.props;
    this.setState({
      remarksCasesLoading: true,
    });
    const filters = [
      {
        name: 'Open',
        isSelected: true,
      },
    ];
    filters.push({
      name: 'Banca Case',
      isSelected: true,
    });
    filters.push({
      name: 'showAllForAdmin',
      isSelected: true,
    });
    let doctorId = null;
    if (unassigned) {
      doctorId = 'unassigned';
    }
    if (unattempted) {
      filters.push({
        name: 'Unattempted',
        isSelected: true,
      });
    }
    fetchConsults(authToken, filters, null, null, null, doctorId)
      .then((res) => {
        const { consultations } = res.responseBody;
        console.log(res.responseURL);
        this.requestUrl = res.responseURL;
        this.setState({
          remarksCases: consultations,
          remarksPages: 2,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          remarksCasesLoading: false,
        });
      });
  }

  getDuplicateCase() {
    const { authToken } = this.props;
    this.setState({
      remarksCasesLoading: true,
    });

    //doubt about filters
    const filters = [
      {
        name: 'Open',
        isSelected: true,
      },
    ];
    filters.push({
      name: 'Duplicate Case',
      isSelected: true,
    });
    filters.push({
      name: 'showAllForAdmin',
      isSelected: true,
    });
    fetchConsults(authToken, filters, null, null, null)
      .then((res) => {
        const { consultations } = res.responseBody;
        console.log(res.responseURL);
        this.requestUrl = res.responseURL;
        this.setState({
          remarksCases: consultations,
          remarksPages: 2,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          remarksCasesLoading: false,
        });
      });
  }

  getPaytmCase(unattempted = false) {
    const { authToken } = this.props;
    this.setState({
      remarksCasesLoading: true,
    });
    const filters = [
      {
        name: 'Open',
        isSelected: true,
      },
    ];
    filters.push({
      name: 'Paytm Case',
      isSelected: true,
    });
    filters.push({
      name: 'showAllForAdmin',
      isSelected: true,
    });
    if (unattempted) {
      filters.push({
        name: 'Unattempted',
        isSelected: true,
      });
    }
    fetchConsults(authToken, filters, null, null, null)
      .then((res) => {
        const { consultations } = res.responseBody;
        console.log(res.responseURL);
        this.requestUrl = res.responseURL;
        this.setState({
          remarksCases: consultations,
          remarksPages: 2,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          remarksCasesLoading: false,
        });
      });
  }

  getDailerCase(dailerStatus, videoCase) {
    const { authToken } = this.props;
    this.setState({
      remarksCasesLoading: true,
    });
    const filters = [
      {
        name: 'Open',
        isSelected: true,
      },
    ];
    if (dailerStatus) {
      filters.push({
        name: 'Dailer Answered',
        isSelected: true,
      });
    } else {
      filters.push({
        name: 'Dailer Unanswered',
        isSelected: true,
      });
    }
    if (videoCase) {
      filters.push({
        name: 'VideoCall cases',
        isSelected: true,
      });
    }
    filters.push({
      name: 'showAllForAdmin',
      isSelected: true,
    });
    fetchConsults(authToken, filters, null, null, null)
      .then((res) => {
        const { consultations } = res.responseBody;
        console.log(res.responseURL);
        this.requestUrl = res.responseURL;
        this.setState({
          remarksCases: consultations,
          remarksPages: 2,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          remarksCasesLoading: false,
        });
      });
  }

  getLicBranchCase(val) {
    const { authToken } = this.props;
    this.setState({
      remarksCasesLoading: true,
    });
    const filters = [
      {
        name: 'Open',
        isSelected: true,
      },
    ];
    filters.push({
      name: 'division',
      value: val,
      isSelected: true,
    });
    filters.push({
      name: 'showAllForAdmin',
      isSelected: true,
    });
    fetchConsults(authToken, filters, null, null, null)
      .then((res) => {
        const { consultations } = res.responseBody;
        console.log(res.responseURL);
        this.requestUrl = res.responseURL;
        this.setState({
          remarksCases: consultations,
          remarksPages: 2,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          remarksCasesLoading: false,
        });
      });
  }

  getVendorCase() {
    const { authToken } = this.props;
    const vendorId = 1;
    this.setState({
      remarksCasesLoading: true,
    });
    const filters = [
      {
        name: 'Open',
        isSelected: true,
      },
    ];
    filters.push({
      name: 'vendorCase',
      value: 1,
      isSelected: true,
    });
    filters.push({
      name: 'showAllForAdmin',
      isSelected: true,
    });
    fetchConsults(authToken, filters, null, null, null)
      .then((res) => {
        const { consultations } = res.responseBody;
        console.log(res.responseURL);
        this.requestUrl = res.responseURL;
        this.setState({
          remarksCases: consultations,
          remarksPages: 2,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          remarksCasesLoading: false,
        });
      });
  }

  getCasesForRemark(remarkId, manualRender = false) {
    // if (this.props.insurer !== 'max' && !manualRender) {
    //   return;
    // }
    this.setState({
      remarksCasesLoading: true,
    });
    getCasesForRemark(remarkId)
      .then((res) => {
        this.setState({
          remarksCases: res.consultations,
          remarksPages: Number.parseInt(res.consultations / 20) + 1,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          remarksCasesLoading: false,
        });
      });
  }

  getDiscrepencyCase() {
    const { authToken } = this.props;
    this.setState({
      remarksCasesLoading: true,
    });
    const filters = [
      {
        name: 'Open',
        isSelected: true,
      },
    ];
    filters.push({
      name: 'discrepencyCase',
      isSelected: true,
    });
    filters.push({
      name: 'showAllForAdmin',
      isSelected: true,
    });
    fetchConsults(authToken, filters, null, null, null)
      .then((res) => {
        const { consultations } = res.responseBody;
        console.log(res.responseURL);
        this.requestUrl = res.responseURL;
        this.setState({
          remarksCases: consultations,
          remarksPages: 2,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          remarksCasesLoading: false,
        });
      });
  }

  getZooperCase() {
    const { authToken } = this.props;
    this.setState({
      remarksCasesLoading: true,
    });
    const filters = [
      {
        name: 'Open',
        isSelected: true,
      },
    ];
    filters.push({
      name: 'ZOOPER',
      isSelected: true,
    });
    filters.push({
      name: 'showAllForAdmin',
      isSelected: true,
    });
    fetchConsults(authToken, filters, null, null, null)
      .then((res) => {
        const { consultations } = res.responseBody;
        console.log(res.responseURL);
        this.requestUrl = res.responseURL;
        this.setState({
          remarksCases: consultations,
          remarksPages: 2,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          remarksCasesLoading: false,
        });
      });
  }

  bulkTransferCases = () => {
    const {
      selectedTransferDoctorId,
      remarkCasesSelected,
      remarksCases,
      selectedRemark,
    } = this.state;
    // Find the corresponding doctorId
    const userIds = [];
    remarkCasesSelected.forEach((ele) => {
      const consult = remarksCases.find((cons) => ele === cons.consultationId);
      if (consult) {
        userIds.push(consult.user.userId);
      }
    });
    this.setState({
      transferLoading: true,
    });
    transferCases(userIds, selectedTransferDoctorId)
      .then(() => {
        this.setState({
          selectedTransferDoctorId: -1,
          remarkCasesSelected: new Set(),
        });
        this.getCasesForRemark(selectedRemark.value, true);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          transferLoading: false,
          transferModalShown: false,
        });
      });
  };

  hideModal = () => {
    this.setState({
      transferModalShown: false,
    });
  };

  onDoctorSelected = (ele, action) => {
    if (action.action === 'set-value' || action.action === 'select-option') {
      this.setState({
        selectedTransferDoctorId: ele.value,
      });
    }
  };

  showBulkTransferModal = () => {
    this.setState({
      transferModalShown: true,
    });
  };

  fetchPendingCases = (state) => {
    const { selectedRemark } = this.state;
    const { authToken } = this.props;
    if (!selectedRemark) {
      return;
    }
    if (selectedRemark.label === 'open') {
      const { page } = state;
      fetchConsultsOnPagination(authToken, page, this.requestUrl)
        .then((res) => {
          const { consultations } = res;
          console.log(consultations);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  changePage = (page) => {
    const { authToken } = this.props;
    const { remarksPages, remarksPage, selectedRemark } = this.state;
    this.setState({
      remarksCasesLoading: true,
    });
    console.log(page);
    console.log(page, remarksPage);
    if (selectedRemark.openFilter) {
      fetchConsultsOnPagination(authToken, page + 1, this.requestUrl)
        .then((res) => {
          console.log(res);
          const { consultations } = res;
          this.setState({
            remarksCases: consultations,
            remarksPage: page,
          });
          if (
            page > remarksPage &&
            consultations.length === 20 &&
            remarksPages === page + 1
          ) {
            this.setState({
              remarksPages: remarksPages + 1,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.setState({
            remarksCasesLoading: false,
          });
        });
    } else {
      if (page < remarksPages) {
        this.setState({
          remarksPage: page,
        });
      }
    }
  };

  fetchAll = (page) => {
    const { authToken } = this.props;
    const { selectedRemark, allCases, remarksCases } = this.state;
    this.setState({
      transferLoading: true,
    });
    console.log(page, 'page');
    return fetchConsultsOnPagination(authToken, page, this.requestUrl)
      .then((res) => {
        console.log(res);
        const { consultations } = res;
        this.setState(
          {
            allCases: [...remarksCases, ...allCases, ...consultations],
          },
          () => {
            if (consultations.length === 20) {
              return this.fetchAll(page + 1);
            } else {
              this.transferAllToVendor([
                ...remarksCases,
                ...allCases,
                ...consultations,
              ]);
            }
          },
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getTimeText = (timetoken) => {
    // const docMin = doctorTimetoken / 60;
    const min = parseInt((new Date().valueOf() / 1000 - timetoken) / 60);
    if (min < 60) {
      return `${min}min`;
    } else if (min < 3600) {
      return `${parseInt(min / 60)}h`;
    } else {
      return `${parseInt(min / (60 * 24))}days`;
    }
  };

  getDateText = (createdAt) => {
    // converting in moment format
    createdAt = createdAt * 1000;

    if (!createdAt) {
      return null;
    }

    const latestTime = moment(createdAt);

    const currentTime = moment();
    if (latestTime.format('DD') !== currentTime.format('DD')) {
      return latestTime.format('D/MMM/YYYY');
    } else {
      return latestTime.format('h:mm A');
    }
  };

  autoDailer = () => {
    this.setState({
      autoDailing: true,
    });
    autoDailer()
      .then(() => { })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          autoDailing: false,
        });
      });
  };

  transferSelectedToVendor = () => {
    const {
      selectedTransferDoctorId,
      remarkCasesSelected,
      remarksCases,
      selectedRemark,
    } = this.state;
    const vendorId = 1;
    const userIds = [];
    remarkCasesSelected.forEach((ele) => {
      const consult = remarksCases.find((cons) => ele === cons.consultationId);
      if (consult) {
        userIds.push(consult.user.userId);
      }
    });
    this.setState({
      transferLoading: true,
    });
    this.transferToVendor(userIds);
  };

  transferToVendor = (userIds) => {
    const vendorId = 1;
    const { selectedRemark } = this.state;
    transferToVendor(userIds, vendorId).then(() => {
      this.setState({
        allCases: [],
        transferLoading: false,
      });
      this.getCasesForRemark(selectedRemark.value, true);
    });
  };

  transferAllToVendor = (allCases) => {
    const userIds = [...new Set(allCases.map((item) => item.user.userId))];
    this.transferToVendor(userIds);
    this.setState({
      selectedTransferDoctorId: -1,
      remarkCasesSelected: new Set(),
    });
  };

  render() {
    const {
      doctorsList,
      remarksCases,
      remarksCasesLoading,
      remarkCasesSelected,
      remarksPage,
      remarksPages,
      selectedRemark,
      transferModalShown,
      callRemarksOptions,
      currentTab,
      loading,
      uploadingSignature,
      uploadSignatureDoctorId,
      selectedTransferDoctorId,
    } = this.state;
    console.log(selectedRemark);
    const transferDoctorsList =
      selectedRemark && selectedRemark.value === 'unassignedQc'
        ? this.state.qcDoctors
        : this.props.doctorsList;
    const ActionCell = ({ original }) => {
      const uploadingDoctorSignature =
        original.doctorId === uploadSignatureDoctorId;
      return (
        <>
          <ActionContainer
            onTap={() => this.toggleActive(original.doctorId, original.active)}
          >
            <PowerButton />
            <Cell as="span">
              {original.active ? 'Make Inactive' : 'Make Active'}
            </Cell>
          </ActionContainer>
          <ActionContainer
            onTap={() => this.uploadSignature(original.doctorId)}
          >
            <input
              type="file"
              style={{ display: 'none' }}
              accept=".jpg,.png,.jpeg"
              id={`signatureFile-${original.doctorId}`}
              onChange={this.handleSignatureUpload(original.doctorId)}
            />
            <UploadButton />
            <Cell as="span">Upload Signature</Cell>
          </ActionContainer>
          {original.doctorSignature && (
            <ActionContainer
              onTap={() => this.viewSignature(original.doctorSignature)}
            >
              <SignatureIcon />
              <Cell as="span">View Signature</Cell>
            </ActionContainer>
          )}
          {uploadingSignature && uploadingDoctorSignature && (
            <UploadingComponent
              uploadProgress={`${this.state.uploadProgress}%`}
              borderRadius={0}
            />
          )}
        </>
      );
    };
    const DoctorNameHeader = () => {
      return (
        <TabItemContainer>
          <TabItemText>Admin</TabItemText>
        </TabItemContainer>
      );
    };
    const ActiveConsultsHeader = () => {
      return (
        <TabItemContainer>
          <TabItemText>Active Consults</TabItemText>
        </TabItemContainer>
      );
    };
    const ActionsHeader = () => {
      return (
        <TabItemContainer>
          <TabItemText>Actions</TabItemText>
        </TabItemContainer>
      );
    };
    const TableCell = (props) => {
      return <Cell>{props.value}</Cell>;
    };
    const columns = [
      {
        Header: DoctorNameHeader,
        Cell: (props) => {
          const [toggleValue, setToggleValue] = useState(
            props.original.instantTele === 1,
          );
          const handleToggle = (checked) => {
            setToggleValue(checked);
            updateInstantTele(props.original.doctorId, checked);
          };

          return (
            <Cell>
              {props.value} <br />{' '}
              {props.original.insurer === 'max' && (
                <>
                  <div>
                    {props.original.currentStatus && (
                      <Tag color="magenta">
                        Status: {props.original.currentStatus.replace('_', ' ')}
                      </Tag>
                    )}
                  </div>
                  <div>
                    <span>Instant Tele: </span>
                    <Switch
                      size="small"
                      checked={toggleValue}
                      onChange={handleToggle}
                    />
                  </div>
                </>
              )}
            </Cell>
          );
        },
        accessor: 'doctorName',
      },
      {
        Header: <ActiveConsultsHeader />,
        Cell: TableCell,
        accessor: 'count',
      },
      {
        Header: <ActionsHeader />,
        Cell: ActionCell,
      },
    ];
    const remarksCasesColumns = [
      {
        Header: (
          <TabItemContainer>
            <TabItemText>User Name</TabItemText>
          </TabItemContainer>
        ),
        Cell: (row) => (
          <Cell>
            {`${row.original.user.userName} ${row.original.requirementCode
              ? `(${row.original.requirementCode})`
              : ''
              }`}
            <RightContainer>
              <DateText>{this.getDateText(row.original.createdAt)}</DateText>
              {this.getTimeText(row.original.createdAtEpoch) ? (
                <TimeText>
                  {this.getTimeText(row.original.createdAtEpoch)}
                </TimeText>
              ) : null}
            </RightContainer>
          </Cell>
        ),
        // accessor: 'doctorName',
      },
      {
        Header: (
          <TabItemContainer>
            <TabItemText>Doctor Name</TabItemText>
          </TabItemContainer>
        ),
        Cell: (row) => <Cell>{row.original.doctorName || 'Unassigned'}</Cell>,
      },
      {
        Header: (
          <TabItemContainer>
            <TabItemText>Doctor Remarks</TabItemText>
          </TabItemContainer>
        ),
        Cell: (row) => (
          <Cell>{row.original.remarks || row.original.doctorRemarks}</Cell>
        ),
      },
      {
        Header: (
          <TabItemContainer>
            <TabItemText>Attempts</TabItemText>
          </TabItemContainer>
        ),
        Cell: (row) => <Cell>{row.original.noOfAttempts}</Cell>,
      },
    ];
    const renderTab = () => {
      switch (currentTab) {
        case 'doctorStats':
          return (
            <ReactTable
              data={doctorsList}
              loading={loading}
              columns={columns}
              manual
              style={{
                overflow: 'hidden',
              }}
              getTrProps={(state, rowInfo) => {
                if (rowInfo && rowInfo.original.active === 1) {
                  return {
                    style: {
                      background: 'rgba(66, 204, 59, 0.35)',
                      'border-bottom': 'none',
                    },
                  };
                } else if (rowInfo && rowInfo.original.active === 0) {
                  return {
                    style: {
                      background: 'rgba(215, 35, 35, 0.35)',
                      'border-bottom': 'none',
                    },
                  };
                }
                return {
                  'border-bottom': 'none',
                };
              }}
              getTrGroupProps={() => ({
                style: {
                  'border-bottom': 'none',
                },
              })}
              onFetchData={this.fetchDoctorsList}
            />
          );
        case 'pendingCases':
          return (
            <>
              <Select
                options={[
                  {
                    label: 'Filter by Open Claims',
                    options: [
                      ...this.state.filterOptions,
                      ...this.state.qcPendingDays,
                      ...this.state.divisionList,
                    ],
                  },
                  {
                    label: 'Doctors',
                    options: doctorsList.map((ele) => ({
                      value: ele.doctorId,
                      label: ele.doctorName,
                      openFilter: true,
                    })),
                  },
                  {
                    label: 'Call Remarks',
                    options: callRemarksOptions.map((ele) => ({
                      label: ele.label,
                      value: ele.id,
                      textBoxRemarks: ele.optional === 0,
                    })),
                  },
                  {
                    label: 'Max Case Source',
                    options: [
                      {
                        value: 'Policy Bazaar',
                        label: 'Policy Bazaar',
                        openFilter: true,
                      },
                    ],
                  },
                ]}
                isSearchable={true}
                placeholder="Filter cases by remark or open"
                value={
                  selectedRemark
                    ? {
                      label: selectedRemark.label,
                      value: selectedRemark.id,
                    }
                    : null
                }
                onChange={this.selectRemarkToSearch}
                className="mt-2 ml-2 mr-2 select-options"
              />
              <PrimaryButton
                type="button"
                className="mt-3 float-left text-center align-self-end mr-3"
                onTap={this.showBulkTransferModal}
                disabled={remarkCasesSelected.size === 0}
              >
                <Text fontSize="16px" color="white">
                  Transfer Selected Cases
                </Text>
              </PrimaryButton>

              {this.props.insurer === 'ipru' &&
                !this.props.vendorId &&
                !this.props.qc &&
                selectedRemark ? (
                <div className="d-flex justify-content-center">
                  <PrimaryButton
                    type="button"
                    className="mt-3 float-left text-center align-self-end mr-3"
                    onTap={this.transferSelectedToVendor}
                    disabled={remarkCasesSelected.size === 0}
                  >
                    <Text fontSize="16px" color="white">
                      Transfer Selected To Vendor
                    </Text>
                  </PrimaryButton>

                  <PrimaryButton
                    type="button"
                    className="mt-3 float-left text-center align-self-end mr-3"
                    onTap={() => this.fetchAll(remarksPage + 1)}
                  >
                    <Text fontSize="16px" color="white">
                      Transfer All to vendor
                    </Text>
                  </PrimaryButton>
                  <PrimaryButton
                    type="button"
                    className="mt-3 float-left text-center align-self-end mr-3"
                    onTap={this.autoDailer}
                    loading={this.state.autoDailing}
                  >
                    <Text fontSize="16px" color="white">
                      Auto dailer
                    </Text>
                  </PrimaryButton>
                </div>
              ) : (
                ''
              )}
              {this.props.insurer === 'abhi' &&
                !this.props.vendorId &&
                !this.props.qc &&
                selectedRemark ? (
                <div className="d-flex justify-content-center">
                  <PrimaryButton
                    type="button"
                    className="mt-3 float-left text-center align-self-end mr-3"
                    onTap={this.autoDailer}
                    loading={this.state.autoDailing}
                  >
                    <Text fontSize="16px" color="white">
                      Auto dailer
                    </Text>
                  </PrimaryButton>
                </div>
              ) : (
                ''
              )}

              <SelectTable
                data={remarksCases}
                keyField="consultationId"
                className="mt-3"
                loading={remarksCasesLoading}
                columns={remarksCasesColumns}
                selectType="checkbox"
                showPageJump={false}
                showPageSizeOptions={false}
                manual
                pages={remarksPages}
                page={remarksPage}
                // onFetchData={this.fetchPendingCases}
                onPageChange={this.changePage}
                noDataText={
                  selectedRemark
                    ? 'No Cases found'
                    : 'Please select a remark to continue'
                }
                isSelected={this.isRemarksCaseSelected}
                toggleSelection={this.toggleRemarksCase}
                selectAll={remarkCasesSelected.size === remarksCases.length}
                toggleAll={this.toggleAllRemarksCases}
                style={{
                  overflow: 'hidden',
                }}
                getTdProps={() => {
                  return {
                    style: {
                      'white-space': 'unset',
                    },
                  };
                }}
              // onFetchData={this.fetchDoctorsList}
              />
            </>
          );
        default:
          return false;
      }
    };
    console.log(transferDoctorsList, 'transferDoctorsList');
    return (
      <OuterContainer>
        <TabContainer>
          <TabItemContainer
            active={currentTab === 'doctorStats'}
            onTap={() => {
              this.setState({
                currentTab: 'doctorStats',
              });
            }}
          >
            {currentTab === 'doctorStats' ? (
              <TabActiveItemText>Doctor Stats</TabActiveItemText>
            ) : (
              <TabItemText>Doctor Stats</TabItemText>
            )}
          </TabItemContainer>
          <TabItemContainer
            active={currentTab === 'pendingCases'}
            onTap={() => {
              this.setState({
                currentTab: 'pendingCases',
              });
            }}
          >
            {currentTab === 'pendingCases' ? (
              <TabActiveItemText>Pending Cases</TabActiveItemText>
            ) : (
              <TabItemText>Pending Cases</TabItemText>
            )}
          </TabItemContainer>
        </TabContainer>
        {renderTab()}
        {transferModalShown && (
          <Modal>
            <ModalContainer>
              <ModalBody>
                <ModalHeader>
                  <HeaderText>
                    {`Please select a doctor to transfer the consult(s) to`}
                  </HeaderText>
                  <ButtonsComponent>
                    <CloseContainer onTap={this.hideModal}>
                      <CrossIcon />
                    </CloseContainer>
                  </ButtonsComponent>
                </ModalHeader>

                <Body>
                  <Select
                    options={transferDoctorsList.map((ele) => ({
                      value: ele.doctorId,
                      label: `${ele.doctorName} ${ele.vendorName ? `(${ele.vendorName})` : ''
                        } ( Monthly Target : ${ele.dailyTarget === null
                          ? 'Unset'
                          : `${ele.dailyTarget}`
                        } Completed Count : ${ele.completeCount} )`,
                    }))}
                    onChange={this.onDoctorSelected}
                    isDisabled={this.state.transferLoading}
                  />
                  <ButtonComponent>
                    <Button
                      onTap={this.bulkTransferCases}
                      disabled={selectedTransferDoctorId === -1}
                      loading={this.state.transferLoading}
                    >
                      <Text fontSize="16px" color="white">
                        Transfer
                      </Text>
                    </Button>
                  </ButtonComponent>
                </Body>
              </ModalBody>
            </ModalContainer>
          </Modal>
        )}
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  doctorsList: state.info.doctorsList,
  authToken: state.user.authToken,
  insurer: state.user.insurer,
  qc: state.user.qc,
  vendorId: state.user.vendorId,
});

export default connect(mapStateToProps)(AdminPanel);
